import { useEffect, useState, useRef } from 'react';

import { useForm, Controller } from "react-hook-form";

import {
  ChakraProvider,
  Box,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  GridItem,
  Stack,
  Checkbox,
  TableContainer,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';

import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ChartComponent } from '@syncfusion/ej2-react-charts';

import { StepperComponent, StepsDirective, StepDirective } from '@syncfusion/ej2-react-navigations';

import moment from 'moment';
import { useToast } from '@chakra-ui/react';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';

import { getConfiguration, updateConfiguration } from './lib/configuration';

import { eventTimePrediction } from './lib/prediction';
import {
  getEventRegions,
  getEventLocations,
  getEventFormats,
  getVenues,
  createEvent,
  updateEvent as updateEventRecord,
  getEvents,
  searchEventInstances,
  getEventInstances,
  updateEventInstance,
  getEventInstanceJoiningInstructions,
  createEventInstanceJoiningInstructions,
  updateEventInstanceJoiningInstructions,
} from './lib/events';

const DecoratePage = ({ content }) => {
  return (<>
    <ChakraProvider theme={theme}>
      {content}
    </ChakraProvider>
  </>)
}

const Events = ({ decorate }) => {
  const [ isLoaded, setLoaded ] = useState(false);
  
  const [ portalUrl, setPortalUrl ] = useState('');

  const [ recurring, setRecurring ] = useState(false);
  const [ recurringRules, setRecurringRules ] = useState([{
    type: "First", // weekly, first_weekday, second_weekday, third_weekday, fourth_weekday, last_weekday
    days: [],
  }]);

  const [ eventSchedule, setEventSchedule ] = useState([]);
  const [ eventScheduleLoaded, setEventScheduleLoaded ] = useState(false);

  const [ eventRegions, setEventRegions ] = useState([]);
  const [ eventLocations, setEventLocations ] = useState([]);
  const [ eventTypes, setEventTypes ] = useState([]);
  const [ eventVenues, setEventVenues ] = useState([]);

  const [ eventRegionsLoaded, setEventRegionsLoaded ] = useState(false);
  const [ eventLocationsLoaded, setEventLocationsLoaded ] = useState(false);
  const [ eventTypesLoaded, setEventTypesLoaded ] = useState(false);
  const [ eventVenuesLoaded, setEventVenuesLoaded ] = useState(false);

  const [ eventTypeStepEnabled, setEventTypeStepEnabled ] = useState(true);
  const [ eventDescriptionStepEnabled, setEventDescriptionStepEnabled ] = useState(false);
  const [ schedulingStepEnabled, setSchedulingStepEnabled ] = useState(false);
  const [ communicationsStepEnabled, setCommunicationsStepEnabled ] = useState(false);
  const [ codeOfConductStepEnabled, setCodeOfConductStepEnabled ] = useState(false);
  const [ pricingStepEnabled, setPricingStepEnabled ] = useState(false);
  const [ confirmationStepEnabled, setConfirmationStepEnabled ] = useState(false);

  const [ events, setEvents ] = useState([]);
  const [ eventsLoaded, setEventsLoaded ] = useState(false);
  const [ eventInstances, setEventInstances ] = useState([]);
  const [ eventInstancesLoaded, setEventInstancesLoaded ] = useState(false);

  const [ eventInstanceJoiningInstructionsOpen, setEventInstanceJoiningInstructionsOpen ] = useState(false);
  const [ eventInstanceJoiningInstructions, setEventInstanceJoiningInstructions ] = useState({});

  const [ eventComm1Week, setEventComm1Week ] = useState(true);
  const [ eventComm5Day, setEventComm5Day ] = useState(true);
  const [ eventComm2Day, setEventComm2Day ] = useState(true);
  const [ eventComm1Day, setEventComm1Day ] = useState(true);
  const [ eventComm12Hour, setEventComm12Hour ] = useState(true);
  const [ eventComm6Hour, setEventComm6Hour ] = useState(true);
  const [ eventComm1Hour, setEventComm1Hour ] = useState(true);
  const [ eventComm30Minutes, setEventComm30Minutes ] = useState(true);
  const [ eventComm5Minutes, setEventComm5Minutes ] = useState(true);
  const [ eventComm1Minute, setEventComm1Minute ] = useState(true);
  const [ eventCommEmail, setEventCommEmail ] = useState("");

  const [ editEventInstance, setEditEventInstance ] = useState(null);

  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: createEventOpen,
    onOpen: onCreateEventOpen,
    onClose: onCreateEventClose
  } = useDisclosure();
  const [ activeModalStep, setActiveModalStep] = useState(0);

  const {
    handleSubmit: eventTypeDescription,
    control: controlEventType,
    watch: watchEventType,
    register: registerEventType,
    reset: resetEventType,
    formState: { errors: errorsEventType },
  } = useForm({
    mode: "onChange"
  });
  
  const {
    handleSubmit: handleSubmitEventDescription,
    control: controlEventDescription,
    watch: watchEventDescription,
    register: registerEventDescription,
    reset: resetEventDescription,
    formState: { errors: errorsEventDescription },
  } = useForm({
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitScheduling,
    control: controlScheduling,
    watch: watchScheduling,
    register: registerScheduling,
    reset: resetScheduling,
    formState: { errors: errorsScheduling },
  } = useForm({
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitCodeOfConduct,
    control: controlCodeOfConduct,
    watch: watchCodeOfConduct,
    register: registerCodeOfConduct,
    reset: resetCodeOfConduct,
    formState: { errors: errorsCodeOfConduct },
  } = useForm({
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitPricing,
    control: controlPricing,
    watch: watchPricing,
    register: registerPricing,
    reset: resetPricing,
    formState: { errors: errorsPricing },
  } = useForm({
    mode: "onChange"
  });

  const { 
    handleSubmit: handleSubmitEventJoiningInstructions,
    control: controlEventJoiningInstructions,
    watch: watchEventJoiningInstructions,
    register: registerEventJoiningInstructions,
    reset: resetEventJoiningInstructions,
    formState: { errors: errorsEventJoiningInstructions },
  } = useForm({
    mode: "onChange"
  });

  const eventType = {
    region: {
      label: "Region",
      type: "dropdown",
      placeholder: "Event Region",
      defaultValue: "",
      options: eventRegions,
      rules: {
        required: true,
      },
    },
    location: {
      label: "Event Location",
      type: "dropdown",
      placeholder: "Event Region",
      defaultValue: "",
      options: eventLocations,
      rules: {
        required: true,
      },
    },
    type: {
      label: "Type",
      type: "dropdown",
      placeholder: "Event Type",
      defaultValue: "",
      options: eventTypes,
      rules: {
        required: true,
      },
    },
    venue: {
      label: "Venue",
      type: "dropdown",
      placeholder: "Venue",
      defaultValue: "",
      options: eventVenues,
      rules: {
        required: true,
      },
    }
  }
  
  const eventCreateDescription = {
    name: {
      label: "Name",
      type: "text",
      placeholder: "Name of Event",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    description: {
      label: "Description",
      type: "textarea",
      placeholder: "Description of Event",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image1: {
      label: "Image 1",
      type: "file",
      placeholder: "Image 1",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image2: {
      label: "Image 2",
      type: "file",
      placeholder: "Image 2",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image3: {
      label: "Image 3",
      type: "file",
      placeholder: "Image 3",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image4: {
      label: "Image 4",
      type: "file",
      placeholder: "Image 4",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    image5: {
      label: "Image 5",
      type: "file",
      placeholder: "Image 5",
      defaultValue: "",
      rules: {
        required: true,
      },
    }
  }
  
  const scheduling = {
    startDate: {
      label: "Start Date",
      type: "datepicker",
      placeholder: "First Event Date",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    startTime: {
      label: "Start time",
      type: "timepicker",
      placeholder: "First Event Time",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    duration: {
      label: "Duration",
      type: "dropdown",
      placeholder: "Duration in minutes",
      defaultValue: 30,
      options: [
        { value: 10, text: "10 minutes" },
        { value: 15, text: "15 minutes" },
        { value: 30, text: "30 minutes" },
        { value: 60, text: "1 hour" },
        { value: 90, text: "1.5 hours" },
        { value: 120, text: "2 hours" },
        { value: 150, text: "2.5 hours" },
        { value: 180, text: "3 hours" },
        { value: 210, text: "3.5 hours" },
        { value: 240, text: "4 hours" },
        { value: 270, text: "4.5 hours" },
        { value: 300, text: "5 hours" },
        { value: 330, text: "5.5 hours" },
        { value: 360, text: "6 hours" },
        { value: 390, text: "6.5 hours" },
        { value: 420, text: "7 hours" },
        { value: 450, text: "7.5 hours" },
        { value: 480, text: "8 hours" },
        { value: 510, text: "8.5 hours" },
        { value: 540, text: "9 hours" },
        { value: 570, text: "9.5 hours" },
        { value: 600, text: "10 hours" },
        { value: 630, text: "10.5 hours" },
        { value: 660, text: "11 hours" },
        { value: 690, text: "11.5 hours" },
        { value: 720, text: "12 hours" },
        { value: 1440, text: "1 day" },
        { value: 2880, text: "2 days" },
        { value: 4320, text: "3 days" },
        { value: 5760, text: "4 days" },
        { value: 7200, text: "5 days" },
        { value: 8640, text: "6 days" },
        { value: 10080, text: "1 week" },
        { value: 20160, text: "2 weeks" },
        { value: 30240, text: "3 weeks" },
        { value: 40320, text: "4 weeks" },
      ],
    },
    endDate: {
      label: "Stop Recurring on",
      type: "datepicker",
      placeholder: "Stop Recurring on",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    recurring: {
      label: "Recurring",
      type: "checkbox",
      placeholder: "Does the event repeat?",
      defaultValue: recurring,
      rules: {
        required: false,
      },
    },
  }

  const code_of_conduct = {
    maximum_industry_conflicts: {
      label: "Maximum Industry Clash",
      type: "number",
      placeholder: "Maximum Number of People from each industry",
      defaultValue: 100,
      rules: {
        required: true,
      },
    },
    codeOfConduct: {
      label: "Code of Conduct",
      type: "text",
      placeholder: "Code of Conduct",
      defaultValue: "https://berlincodeofconduct.org/",
      rules: {
        required: true,
      },
    }
  }

  const pricing = {
    ticketQuantity: {
      label: "Ticket Quantity",
      type: "number",
      placeholder: "Ticket Quantity",
      defaultValue: 25,
      rules: {
        required: true,
      },
    },
    ticketPriceMembers: {
      label: "Ticket Price - Members",
      type: "number",
      placeholder: "Ticket Price - Members",
      defaultValue: 25,
      rules: {
        required: true,
      },
    },
    ticketPriceNonMembers: {
      label: "Ticket Price - Non Members",
      type: "number",
      placeholder: "Ticket Price - Non Members",
      defaultValue: 25,
      rules: {
        required: true,
      },
    },
    creditPriceMembers: {
      label: "Credit Price - Members",
      type: "number",
      placeholder: "Credit Price - Members",
      defaultValue: 25,
      rules: {
        required: true,
      },
    },
    creditPriceNonMembers: {
      label: "Credit Price - Non Members",
      type: "number",
      placeholder: "Credit Price - Non Members",
      defaultValue: 25,
      rules: {
        required: true,
      },
    }
  }

  const event_instance = {
    type: {
      label: "Type",
      type: "dropdown",
      placeholder: "Type",
      defaultValue: "instructions",
      options: [ "link", "instructions", "hybrid" ],
      rules: {
        required: true,
      },
    },
    hyperlink: {
      label: "Hyperlink",
      type: "text",
      placeholder: "Hyperlink",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    instructions: {
      label: "Instructions",
      type: "textarea",
      placeholder: "Instructions",
      defaultValue: "",
      rules: {
        required: true,
      },
    }
  }

  const stepper = useRef();

  watchScheduling(({recurring}) => {
    setRecurring(recurring);;
  });

  watchEventType(({region, location, type, venue}) => {
    setEventDescriptionStepEnabled(region && location && type && venue);
  });

  watchEventDescription(({name, description}) => {
    setSchedulingStepEnabled(name && description);
  });

  watchScheduling(({startDate, startTime, endDate}) => {
    setCommunicationsStepEnabled(startDate && startTime && endDate);
  });


  watchCodeOfConduct(({maximum_industry_conflicts, codeOfConduct}) => {
    setPricingStepEnabled(codeOfConduct);
  });

  watchPricing(({ticketPriceMembers, ticketPriceNonMembers, creditPriceMembers, creditPriceNonMembers}) => {
    setConfirmationStepEnabled(ticketPriceMembers && ticketPriceNonMembers && creditPriceMembers && creditPriceNonMembers);
  });

  const Input = ({ value, onChange, type, ...rest }) => {
    switch (type) {
      case "text":
        return (
          <TextBoxComponent
            placeholder={rest?.placeholder}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
      case "number":
          return (
            <NumericTextBoxComponent
              placeholder={rest?.placeholder}
              change={({ value }) => onChange(value)}
              value={value}
            />
          );

        case "textarea":
          return (
            <TextBoxComponent
              multiline={true}
              placeholder={rest?.placeholder}
              change={({ value }) => onChange(value)}
              CssClass="e-bigger"
              value={value}
            />
          );

      case "radio":
        return rest?.options.map((e) => (
          <RadioButtonComponent
            key={e}
            label={e}
            value={e}
            onChange={(value) => onChange(value)}
            checked={value === e}
          />
        ));
      case "dropdown":
        return (
          <DropDownListComponent
            dataSource={rest?.options}
            allowFiltering={true}
            filterType="contains"
            select={({ itemData }) => {
              onChange(itemData.value);
            }}
            placeholder={rest?.placeholder}
            value={value}
            popupHeight="250px" // Set the height of the dropdown popup
            enableVirtualization={true} // Enable virtualization for better performance
            allowScrolling={true} // Enable scrolling for the dropdown
            scrollSettings={{ enable: true }} // Enable scrolling for the dropdown
          />
        );
   
      case "checkbox":
        return (
          <CheckBoxComponent
            label={rest?.checkboxLabel}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        );

      case "file":
        return (<>
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onload = (e) => {
                onChange(e.target.result);
              };
              reader.readAsDataURL(file);
            }}
            />
          { value && <img src={value} style={{ maxWidth: 250 }} /> }
        </>);

      case "datepicker":
        return (
          <DatePickerComponent
            label={rest?.checkboxLabel}
            change={({ value }) => onChange(value)}
            value={value}
            openOnFocus={true}
            format={'EEEE, dd MMMM yyyy'}
          />
        );

      case "timepicker":
        return (
          <TimePickerComponent
            label={rest?.checkboxLabel}
            change={({ value }) => onChange(value)}
            value={value}
            openOnFocus={true}
            format={'h:mm a'}
          />
        );
   
      default:
        return null;
    }
  };
  
  const eventTypeInputs = Object.keys(eventType).map((e) => {
    const { rules, defaultValue, label } = eventType[e];
  
    return (
      <section style={{ marginBottom: 16, marginTop: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlEventType}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...eventType[e]}
              />
            </div>
          )}
        />
        {/*errorsEventType[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const eventSchedulingInputs = Object.keys(scheduling).map((e) => {
    const { rules, defaultValue, label } = scheduling[e];
    
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlScheduling}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...scheduling[e]}
              />
            </div>
          )}
        />
        {/*errorsScheduling[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const eventCreateDescriptionInputs = Object.keys(eventCreateDescription).map((e) => {
    const { rules, defaultValue, label } = eventCreateDescription[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlEventDescription}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...eventCreateDescription[e]}
              />
            </div>
          )}
        />
        {/*errorsEventDescription[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const codeOfConductInputs = Object.keys(code_of_conduct).map((e) => {
    const { rules, defaultValue, label } = code_of_conduct[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlCodeOfConduct}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...code_of_conduct[e]}
              />
            </div>
          )}
        />
        {/*errorsCodeOfConduct[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const eventInstanceJoiningInstructionsInputs = Object.keys(event_instance).map((e) => {
    const { rules, defaultValue, label } = event_instance[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlEventJoiningInstructions}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...event_instance[e]}
              />
            </div>
          )}
        />
      </section>
    );
  });

  const pricingInputs = Object.keys(pricing).map((e) => {
    const { rules, defaultValue, label } = pricing[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlPricing}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...pricing[e]}
              />
            </div>
          )}
        />
        {/*errorsPricing[e] && <Error>This field is required</Error>*/}
      </section>
    );
  });

  const createConfirmedEvent = async () => {
    const event = {
      region: controlEventType._formValues.region,
      location: controlEventType._formValues.location,
      format: controlEventType._formValues.type,
      venue: controlEventType._formValues.venue,
      name: controlEventDescription._formValues.name,
      description: controlEventDescription._formValues.description,
      start_time: controlScheduling._formValues.startTime,
      start_date: controlScheduling._formValues.startDate,
      duration: controlScheduling._formValues.duration,
      recurring: controlScheduling._formValues.recurring,
      end_date: controlScheduling._formValues.endDate,
      recurringRule: JSON.stringify(recurringRules),
      ticket_quantity: controlPricing._formValues.ticketQuantity,
      ticket_price: controlPricing._formValues.ticketPriceMembers,
      ticket_price_non_member: controlPricing._formValues.ticketPriceNonMembers,
      ticket_credits: controlPricing._formValues.creditPriceMembers,
      ticket_credits_non_member: controlPricing._formValues.creditPriceNonMembers,
      maximum_industry_conflicts: controlCodeOfConduct._formValues.maximum_industry_conflicts,
      code_of_conduct: controlCodeOfConduct._formValues.codeOfConduct,
      image1: controlEventDescription._formValues.image1,
      image2: controlEventDescription._formValues.image2,
      image3: controlEventDescription._formValues.image3,
      image4: controlEventDescription._formValues.image4,
      image5: controlEventDescription._formValues.image5,
      comm_1week: eventComm1Week,
      comm_5day: eventComm5Day,
      comm_2day: eventComm2Day,
      comm_1day: eventComm1Day,
      comm_12hour: eventComm12Hour,
      comm_6hour: eventComm6Hour,
      comm_1hour: eventComm1Hour,
      comm_30minutes: eventComm30Minutes,
      comm_5minutes: eventComm5Minutes,
      comm_1minute: eventComm1Minute,
      eventCommEmail: eventCommEmail,
    }

    console.log("Creating", {
      event
    });

    createEvent(event).then(async () => {
      toast({
        title: "Event Created",
        description: "Event has been created, scheduled events may take a few minutes to appear",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setEventVenuesLoaded(false);
      setEventsLoaded(false);

      // reset forms
      resetEventType();
      resetEventDescription();
      resetScheduling();
      resetPricing();

      setRecurringRules([{
        type: "First",
        days: [],
      }]);

      // set stepper to first step
      setActiveModalStep(0);
      
      onCreateEventClose();

      setLoaded(false);
    });
  }    

  useEffect(() => {
    const fetchData = async () => {
      const loggedIn = await isLoggedIn();
      if (!loggedIn) {
        window.location.href = '/login';
      }

      if (!portalUrl) {
        const { portal_url } = await getConfiguration();
        setPortalUrl(portal_url);
      }

      if (!eventRegionsLoaded) {
        const regions = await getEventRegions();
        setEventRegions(regions.map(({ name }) => name));
        setEventRegionsLoaded(true);
      }

      if (!eventLocationsLoaded) {
        const locations = await getEventLocations();
        setEventLocations(locations.map(({ name }) => name));
        setEventLocationsLoaded(true);
      }

      if (!eventTypesLoaded) {
        const types = await getEventFormats();
        setEventTypes(types.map(({ name }) => name));
        setEventTypesLoaded(true);
      }

      if (!eventVenuesLoaded) {
        const venues = await getVenues();

        if (!eventsLoaded) {
          const events = await getEvents();
          setEvents(events.map(({
            url,
            name,
            description,
            start_date,
            end_date,
            venue,
            image1,
            image2,
            image3,
            image4,
            image5,
          }) => {
            return {
              id: url.split('/')[url.split('/').length - 2],
              venue: venues.find(({ url }) => url === venue)?.name,
              name,
              description,
              start_date,
              end_date,
              image1,
              image2,
              image3,
              image4,
              image5,
            }
          }));
          setEventsLoaded(true);
        }

        if (!eventInstancesLoaded) {
          const eventInstances = await getEventInstances();
          setEventInstances(eventInstances.map(({
            url,
            name,
            start_date,
            end_date,
            venue,
          }) => {
            return {
              url,
              id: url.split('/')[url.split('/').length - 2],
              venue: venues.find(({ url }) => url === venue)?.name,
              name,
              start_date: moment(start_date).format('Do MMM YYYY, h:mm a'),
              end_date: moment(end_date).format('Do MMM YYYY, h:mm a'),
            }
          }));
          setEventInstancesLoaded(true);
        }

        setEventVenues(venues.map(({ name }) => name));
        setEventVenuesLoaded(true);
      }

      if (!eventScheduleLoaded) {
        const instances = await searchEventInstances();
        const processedInstances = instances.map(({
          name, start_date,
        }) => ({
          Subject: name,
          StartTime: new Date(start_date),
          EndTime: new Date((new Date(start_date).setHours(new Date(start_date).getHours() + 2))),
          IsAllDay: false
        }));

        setEventSchedule(processedInstances);
        setEventScheduleLoaded(true);
      }
    };

    if(!isLoaded) {
      setLoaded(true);
      fetchData();
    }
  }, [isLoaded, eventRegionsLoaded, eventLocationsLoaded, eventTypesLoaded, eventVenuesLoaded, eventScheduleLoaded, eventsLoaded, eventInstancesLoaded]);

  const EventType = () => {
    return (<>
      {eventTypeInputs}
      { eventDescriptionStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const EventDescription = () => {
    return (<>
      {eventCreateDescriptionInputs}
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { schedulingStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const Scheduling = ({ active }) => {
    if(!active) {
      <Heading as='h5' size='md'>Scheduling</Heading>
      
      if (!!editEventInstance) {
        return (<>
          <Text>Scheduling not available for event instances</Text>
        </>)
      }
    }
    return (<>
      {eventSchedulingInputs}
      { recurring && <>
        <Heading as='h5' size='md'>Recurring Rules</Heading>
        {recurringRules.map((rule, index) => {
          return (<div key={`recurring_rules_{index}`}>
            <br /><label><strong>Frequency</strong></label><br />
            <DropDownListComponent
              dataSource={[
                { value: "Weekly", text: "Weekly" },
                { value: "First", text: "First" },
                { value: "Second", text: "Second" },
                { value: "Third", text: "Third" },
                { value: "Fourth", text: "Fourth" },
                { value: "Last", text: "Last" },
              ]}
              onChange={({value}) => {
                const newRules = [ ...recurringRules ];
                newRules[index].type = value;
                setRecurringRules(newRules);
              }}
              value={rule.type}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Monday"
              checked={rule.days.includes("monday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "monday" ] : rule.days.filter(e => e !== "monday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Tuesday"
              checked={rule.days.includes("tuesday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "tuesday" ] : rule.days.filter(e => e !== "tuesday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Wednesday"
              checked={rule.days.includes("wednesday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "wednesday" ] : rule.days.filter(e => e !== "wednesday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Thursday"
              checked={rule.days.includes("thursday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "thursday" ] : rule.days.filter(e => e !== "thursday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Friday"
              checked={rule.days.includes("friday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "friday" ] : rule.days.filter(e => e !== "friday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Saturday"
              checked={rule.days.includes("saturday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "saturday" ] : rule.days.filter(e => e !== "saturday");
                setRecurringRules(newRules);
              }}
            />
            <div style={{ display: 'inline', marginRight: 20 }}></div>
            <CheckBoxComponent
              label="Sunday"
              checked={rule.days.includes("sunday")}
              change={({ checked }) => {
                const newRules = [ ...recurringRules ];
                newRules[index].days = checked ? [ ...rule.days, "sunday" ] : rule.days.filter(e => e !== "sunday");
                setRecurringRules(newRules);
              }}
            />
            <br /><br />
            { index > 0 && <Button style={{ marginRight: 5 }}  colorScheme='red' onClick={() => {
              const newRules = [ ...recurringRules ];
              newRules.splice(index, 1);
              setRecurringRules(newRules);
            }}>Remove Rule</Button> }
            <Button colorScheme='cyan' onClick={() => { setRecurringRules([ ...recurringRules, { type: "First", days: [] }]) }}>Add Rule</Button>
          </div>)
        })}
        <br />
        <br />
        
      </> }
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { communicationsStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const Communications = () => {
    return (<>
      {/* {eventSchedulingInputs} */}
      <Box>
          <Stack align="center" direction="row" spacing={4}>
              <Text><strong>Reminder Frequency</strong></Text>
              <GridItem colSpan={16}>
                <Checkbox spacing={2} ml={3} isChecked={eventComm1Week} onChange={() => { setEventComm1Week(!eventComm1Week) }}>1 week</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm5Day} onChange={() => { setEventComm5Day(!eventComm5Day) }}>5 day</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm2Day} onChange={() => { setEventComm2Day(!eventComm2Day) }}>2 day</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm1Day} onChange={() => { setEventComm1Day(!eventComm1Day) }}>1 day</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm12Hour} onChange={() => { setEventComm12Hour(!eventComm12Hour) }}>12 hour</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm6Hour} onChange={() => { setEventComm6Hour(!eventComm6Hour) }}>6 hour</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm1Hour} onChange={() => { setEventComm1Hour(!eventComm1Hour) }}>1 hour</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm30Minutes} onChange={() => { setEventComm30Minutes(!eventComm30Minutes) }}>30 minutes</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm5Minutes} onChange={() => { setEventComm5Minutes(!eventComm5Minutes) }}>5 minutes</Checkbox>
                <Checkbox spacing={2} ml={3} isChecked={eventComm1Minute} onChange={() => { setEventComm1Minute(!eventComm1Minute) }}>1 minute</Checkbox>
              </GridItem>
          </Stack>
          <br />
          <Text><strong>Messaging</strong></Text>
          <TextBoxComponent
            placeholder="..."
            multiline={true}
            change={e => {
              console.log(e);
              setEventCommEmail(e.value);

              if(e.value.length > 3) {
                setCodeOfConductStepEnabled(true);
              }
            }}
            value={eventCommEmail}
          />
      </Box>
      <br />
      <br />
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { codeOfConductStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const CodeOfConduct = () => {
    return (<>
      {codeOfConductInputs}
      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { pricingStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const EventJoiningInstructions = () => {
    return (<>
      {eventInstanceJoiningInstructionsInputs}
      <Button style={{ marginLeft: 5 }} onClick={async () => { 
        console.log({eventInstanceJoiningInstructions});
        if(eventInstanceJoiningInstructions && eventInstanceJoiningInstructions.url) {
          const updateInstructions = await updateEventInstanceJoiningInstructions({
            id: eventInstanceJoiningInstructions.url,
            type: controlEventJoiningInstructions._formValues.type,
            hyperlink: controlEventJoiningInstructions._formValues.hyperlink,
            instructions: controlEventJoiningInstructions._formValues.instructions,
          });

          if(updateInstructions) {
            toast({
              title: "Instructions Updated",
              description: "Instructions have been updated",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Instructions Update Failed",
              description: "Instructions could not be updated",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        } else {
          // find event instance
          const createInstructions = await createEventInstanceJoiningInstructions({
            event_instance_id: eventInstanceJoiningInstructions.event_instance_id,
            type: controlEventJoiningInstructions._formValues.type,
            hyperlink: controlEventJoiningInstructions._formValues.hyperlink,
            instructions: controlEventJoiningInstructions._formValues.instructions,
          });

          if(createInstructions) {
            toast({
              title: "Instructions Created",
              description: "Instructions have been created",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Instructions Creation Failed",
              description: "Instructions could not be created",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        }

        setEventInstanceJoiningInstructionsOpen(false);
        setEventInstanceJoiningInstructions({});
        
       }}>Save</Button>
    </>)
  }

  const Pricing = () => {
    return (<>
      {pricingInputs}

      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
      { confirmationStepEnabled && <Button style={{ marginLeft: 5 }} onClick={() => { setActiveModalStep(activeModalStep + 1); stepper.current.activeStep = stepper.current.activeStep + 1 }}>Next</Button> }
    </>)
  }

  const Confirmation = () => {
    return (<>
      <Heading as='h3' size='lg'>Event Summary</Heading>

      <br />
      <Heading as='h5' size='md'>Event Type</Heading>

      <strong>Region:</strong> {controlEventType._formValues.region}<br />
      <strong>Location:</strong> {controlEventType._formValues.location}<br />
      <strong>Type:</strong> {controlEventType._formValues.type}<br />

      <br />
      <Heading as='h5' size='md'>Event Description</Heading>

      <strong>Event Name:</strong> {controlEventDescription._formValues.name}<br />
      <strong>Event Description:</strong> {controlEventDescription._formValues.description}<br />

      <br />
      <Heading as='h5' size='md'>Code of Conduct</Heading>

      <strong>Maximum Industry Clash:</strong> {controlCodeOfConduct._formValues.maximum_industry_conflicts}<br />
      <strong>Code of Conduct:</strong> {controlCodeOfConduct._formValues.codeOfConduct}<br />
      <br />
      
      <Heading as='h5' size='md'>Scheduling</Heading>
      
      <strong>Start Date:</strong> {moment(controlScheduling._formValues.startDate).format('dddd DD/MM/YYYY')}<br />
      <strong>Start Time:</strong> {moment(controlScheduling._formValues.startTime).format('hh:mm A')}<br />
      <strong>Recurring:</strong> {controlScheduling._formValues.recurring ? "Yes" : "No"}<br />
      
      <br />
      <Heading as='h5' size='md'>Future Events</Heading>
      
      <br />
      <Heading as='h6' size='sm'>Showing next 60 days</Heading>
      <br />
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Day of Week</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            { eventTimePrediction(controlScheduling._formValues.startDate, controlScheduling._formValues.endDate, controlScheduling._formValues.startTime, recurringRules).map(date => {
              return (<Tr>
                <Td>{moment(date).format('dddd')}</Td>
                <Td>{moment(date).format('DD/MM/YYYY')}</Td>
              </Tr>)
            }
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <br /><br />

      <Button colorScheme="green" onClick={createConfirmedEvent}>Create Event</Button>
      <br /><br />

      <Button onClick={() => { setActiveModalStep(activeModalStep - 1); stepper.current.activeStep = stepper.current.activeStep - 1 }}>Back</Button>
    </>)
  }

  const [editEvent, setEditEvent] = useState(null);

  const handleEdit = (row) => {
    setEditEvent(row);

    getEvents().then(async events => {
      const regions = await getEventRegions();
      const locations = await getEventLocations();
      const types = await getEventFormats();
      const venues = await getVenues();

      const event = events.find(({url}) => url.split('/')[url.split('/').length - 2] === row.id);
      
      // update form values
      controlEventType._formValues.region = regions.find(({url}) => event.region == url).name;
      controlEventType._formValues.location = locations.find(({url}) => event.location == url).name;
      controlEventType._formValues.type = types.find(({url}) => event.format == url).name;
      controlEventType._formValues.venue = venues.find(({url}) => event.venue == url).name;
      setEventDescriptionStepEnabled(true);

      // update form values
      controlEventDescription._formValues.name = event.name;
      controlEventDescription._formValues.description = event.description;
      setSchedulingStepEnabled(true);
      
      // update form values
      controlScheduling._formValues.startDate = new Date(event.start_date);
      controlScheduling._formValues.startTime = event.start_time;
      controlScheduling._formValues.recurring = event.recurringRule.length > 5;
      controlScheduling._formValues.endDate = new Date(event.end_date);
      // controlScheduling._formValues.duration = event.duration;
      controlScheduling._formValues.duration = scheduling.duration.options.find(option => option.value === event.duration)?.text || event.duration;

      setRecurring(event.recurringRule.length > 5);
      setRecurringRules(JSON.parse(event.recurringRule));
      console.log("!!!", JSON.parse(event.recurringRule));
      setCommunicationsStepEnabled(true);
      
      // communications
      setCodeOfConductStepEnabled(true);

      // update form values
      controlCodeOfConduct._formValues.maximum_industry_conflicts = event.maximum_industry_conflicts;
      controlCodeOfConduct._formValues.codeOfConduct = event.code_of_conduct;

      setPricingStepEnabled(true);

      // update form values
      controlPricing._formValues.ticketQuantity = event.ticket_quantity;
      controlPricing._formValues.ticketPriceMembers = event.ticket_price_members;
      controlPricing._formValues.ticketPriceNonMembers = event.ticket_price_non_members;
      controlPricing._formValues.creditPriceMembers = event.credit_price_members;
      controlPricing._formValues.creditPriceNonMembers = event.credit_price_non_members;
      setConfirmationStepEnabled(true);

      // event comms
      setEventComm1Week(event.comm_1week);
      setEventComm5Day(event.comm_5day);
      setEventComm2Day(event.comm_2day);
      setEventComm1Day(event.comm_1day);
      setEventComm12Hour(event.comm_12hour);
      setEventComm6Hour(event.comm_6hour);
      setEventComm1Hour(event.comm_1hour);
      setEventComm30Minutes(event.comm_30minutes);
      setEventComm5Minutes(event.comm_5minutes);
      setEventComm1Minute(event.comm_1minute);
      setEventCommEmail(event.eventCommEmail);

      // update stepper
      setActiveModalStep(0);

      // console.log(event, controlEventType._formValues)

      onEditEventOpen();
    });
  };

  const {
    isOpen: editEventOpen,
    onOpen: onEditEventOpen,
    onClose: onEditEventClose
  } = useDisclosure();

  const updateEvent = async () => {
    const updatedEvent = {
      ...editEvent,
      region: controlEventType._formValues.region,
      location: controlEventType._formValues.location,
      format: controlEventType._formValues.type,
      venue: controlEventType._formValues.venue,
      name: controlEventDescription._formValues.name,
      description: controlEventDescription._formValues.description,
      start_time: controlScheduling._formValues.startTime,
      start_date: controlScheduling._formValues.startDate,
      duration: scheduling.duration.options.find(option => option.text === controlScheduling._formValues.duration)?.value || controlScheduling._formValues.duration,
      recurring: controlScheduling._formValues.recurring,
      end_date: controlScheduling._formValues.endDate,
      recurringRule: JSON.stringify(recurringRules),
      ticket_quantity: controlPricing._formValues.ticketQuantity,
      ticket_price: controlPricing._formValues.ticketPriceMembers,
      ticket_price_non_member: controlPricing._formValues.ticketPriceNonMembers,
      ticket_credits: controlPricing._formValues.creditPriceMembers,
      ticket_credits_non_member: controlPricing._formValues.creditPriceNonMembers,
      maximum_industry_conflicts: controlCodeOfConduct._formValues.maximum_industry_conflicts,
      code_of_conduct: controlCodeOfConduct._formValues.codeOfConduct,
      image1: controlEventDescription._formValues.image1,
      image2: controlEventDescription._formValues.image2,
      image3: controlEventDescription._formValues.image3,
      image4: controlEventDescription._formValues.image4,
      image5: controlEventDescription._formValues.image5,
      comm_1week: eventComm1Week,
      comm_5day: eventComm5Day,
      comm_2day: eventComm2Day,
      comm_1day: eventComm1Day,
      comm_12hour: eventComm12Hour,
      comm_6hour: eventComm6Hour,
      comm_1hour: eventComm1Hour,
      comm_30minutes: eventComm30Minutes,
      comm_5minutes: eventComm5Minutes,
      comm_1minute: eventComm1Minute,
      eventCommEmail: eventCommEmail
    };

    console.log("Updating event", updatedEvent);

    updateEventRecord(updatedEvent).then(() => {
      toast({
        title: "Event Updated",
        description: "Event has been updated, scheduled events may take a few minutes to appear",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setEventVenuesLoaded(false);
      setEventsLoaded(false);

      // reset forms
      resetEventType();
      resetEventDescription();
      resetScheduling();
      resetPricing();

      setRecurringRules([{
        type: "First",
        days: [],
      }]);

      // set stepper to first step
      setActiveModalStep(0);
      
      onEditEventClose();

      if(!eventVenues.length) {
        getVenues().then(venues => {
          if(!events.length) {
            getEvents().then(events => {
              setEvents(events.map(({
                url,
                name,
                description,
                start_date,
                end_date,
                venue,
                image1,
                image2,
                image3,
                image4,
                image5,
              }) => {
                return {
                  id: url.split('/')[url.split('/').length - 2],
                  venue: venues.find(({url}) => url === venue)?.name,
                  name,
                  description,
                  start_date,
                  end_date,
                  image1,
                  image2,
                  image3,
                  image4,
                  image5,
                }
              }));
              setEventsLoaded(true);
            });

            getEventInstances().then(eventInstances => {
              setEventInstances(eventInstances.map(({
                url,
                name,
                start_date,
                end_date,
                venue,
              }) => {
                return {
                  url,
                  id: url.split('/')[url.split('/').length - 2],
                  venue: venues.find(({url}) => url === venue)?.name,
                  name,
                  start_date,
                  end_date,
                }
              }));
              setEventInstancesLoaded(true);
            })
          };

          setEventVenues(venues.map(({name}) => name));
          setEventVenuesLoaded(true); 
        });
      }
    });
  };

  const toolbarOptions = ['Search'];
  const searchOptions = {
      fields: ['name', 'description', 'start_date', 'end_date', 'venue'],
      ignoreCase: true,
      operator: 'contains'
  };

  const {
    isOpen: editInstanceOpen,
    onOpen: onEditInstanceOpen,
    onClose: onEditInstanceClose
  } = useDisclosure();

  const updateInstance = async () => {
    const updatedInstance = {
      ...editEventInstance,
      id: editEventInstance.url.split('/')[editEventInstance.url.split('/').length - 2],
      region: controlEventType._formValues.region,
      location: controlEventType._formValues.location,
      format: controlEventType._formValues.type,
      venue: controlEventType._formValues.venue,
      name: controlEventDescription._formValues.name,
      description: controlEventDescription._formValues.description,
      start_time: controlScheduling._formValues.startTime,
      start_date: controlScheduling._formValues.startDate,
      duration: controlScheduling._formValues.duration,
      end_date: controlScheduling._formValues.endDate,
      ticekt_quantity: controlPricing._formValues.ticketQuantity,
      ticket_price_members: controlPricing._formValues.ticketPriceMembers,
      ticket_price_non_members: controlPricing._formValues.ticketPriceNonMembers,
      credit_price_members: controlPricing._formValues.creditPriceMembers,
      credit_price_non_members: controlPricing._formValues.creditPriceNonMembers,
      maximum_industry_conflicts: controlCodeOfConduct._formValues.maximum_industry_conflicts,
      code_of_conduct: controlCodeOfConduct._formValues.codeOfConduct,
    };

    console.log({updatedInstance});
    
    updateEventInstance(updatedInstance).then(() => {
      toast({
        title: "Instance Updated",
        description: "Event instance has been updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setEventVenuesLoaded(false);
      setEventsLoaded(false);

      // reset forms
      resetEventType();
      resetEventDescription();
      resetScheduling();
      resetPricing();

      // set stepper to first step
      setActiveModalStep(0);
      
      onEditInstanceClose();

      getVenues().then(venues => {
        if(!eventsLoaded) {
          getEvents().then(events => {
            setEvents(events.map(({
              url,
              name,
              description,
              start_date,
              end_date,
              venue,
              image1,
              image2,
              image3,
              image4,
              image5,
            }) => {
              return {
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                description,
                start_date,
                end_date,
                image1,
                image2,
                image3,
                image4,
                image5,
              }
            }));
            setEventsLoaded(true);
          });

          getEventInstances().then(eventInstances => {
            setEventInstances(eventInstances.map(({
              url,
              name,
              start_date,
              end_date,
              venue,
            }) => {
              return {
                url,
                id: url.split('/')[url.split('/').length - 2],
                venue: venues.find(({url}) => url === venue)?.name,
                name,
                start_date,
                end_date,
              }
            }));
            setEventInstancesLoaded(true);
          })
        };

        setEventVenues(venues.map(({name}) => name));
        setEventVenuesLoaded(true); 
      });
    });
  };

  const handleEditInstance = (row) => {
    setEditEventInstance(row);

    getEventInstances().then(async eventInstances => {
      const regions = await getEventRegions();
      const locations = await getEventLocations();
      const types = await getEventFormats();
      const venues = await getVenues();

      const eventInstance = eventInstances.find(({url}) => url.split('/')[url.split('/').length - 2] === row.id);
      setEditEventInstance(eventInstance)
      
      // update form values
      try {
        controlEventType._formValues.region = regions.find(({url}) => eventInstance.event_region == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_region.split('(')[0].split(')')[0]).name;
        controlEventType._formValues.location = locations.find(({url}) => eventInstance.event_location == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_location.split('(')[0].split(')')[0]).name;
        controlEventType._formValues.type = types.find(({url}) => eventInstance.event_format == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_format.split('(')[0].split(')')[0]).name;
        controlEventType._formValues.venue = venues.find(({url}) => eventInstance.event_venue == url || url.split('/')[url.split('/').length - 2] == eventInstance.event_venue.split('(')[0].split(')')[0]).name;
      } catch(e) {
        controlEventType._formValues.region = regions[0].name;
        controlEventType._formValues.location = locations[0].name;
        controlEventType._formValues.type = types[0].name;
        controlEventType._formValues.venue = venues[0].name;
      }

      setEventDescriptionStepEnabled(true);

      // update form values
      controlEventDescription._formValues.name = eventInstance.name;
      controlEventDescription._formValues.description = eventInstance.description;
      setSchedulingStepEnabled(true);
      
      // update form values
      controlScheduling._formValues.startDate = new Date(eventInstance.start_date);
      controlScheduling._formValues.startTime = new Date(eventInstance.start_time);
      controlScheduling._formValues.endDate = new Date(eventInstance.end_date);
      controlScheduling._formValues.duration = eventInstance.duration;
      setCommunicationsStepEnabled(true);
      
      // set code of conduct
      setCodeOfConductStepEnabled(true);
      
      // update form values
      controlCodeOfConduct._formValues.maximum_industry_conflicts = eventInstance.maximum_industry_conflicts;
      controlCodeOfConduct._formValues.codeOfConduct = eventInstance.code_of_conduct;
      setPricingStepEnabled(true);

      // update form values
      controlPricing._formValues.ticketQuantity = eventInstance.quantity;
      controlPricing._formValues.ticketPriceMembers = eventInstance.ticket_price_members;
      controlPricing._formValues.ticketPriceNonMembers = eventInstance.ticket_price_non_members;
      controlPricing._formValues.creditPriceMembers = eventInstance.credit_price_members;
      controlPricing._formValues.creditPriceNonMembers = eventInstance.credit_price_non_members;
      setConfirmationStepEnabled(true);

      // update stepper
      setActiveModalStep(0);

      onEditInstanceOpen();
    });
  };

  const content = (<>
    <Modal onClose={onCreateEventClose} isOpen={createEventOpen} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <StepperComponent 
            ref={stepper} created={() => { stepper.current.activeStep = activeModalStep }}
            stepChanged={args => setActiveModalStep(args.activeStep)}>
            <StepsDirective>
              <StepDirective disabled={!eventTypeStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Type' } />
              <StepDirective disabled={!eventDescriptionStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Description ' } />
              <StepDirective disabled={!schedulingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Scheduling' } />
              <StepDirective disabled={!communicationsStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Communication' } />
              <StepDirective disabled={!codeOfConductStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Code of Conduct' } />
              <StepDirective disabled={!pricingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Pricing' } />
              <StepDirective disabled={!confirmationStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Confirmation' } />
            </StepsDirective>
          </StepperComponent>
          <Box marginTop={5}>
            {activeModalStep === 0 && <EventType />}
            {activeModalStep === 1 && <EventDescription />}
            {activeModalStep === 2 && <Scheduling />}
            {activeModalStep === 3 && <Communications />}
            {activeModalStep === 4 && <CodeOfConduct />}
            {activeModalStep === 5 && <Pricing />}
            {activeModalStep === 6 && <Confirmation />}
          </Box>
        </ModalBody>
        <ModalFooter>
          {/* <Button onClick={onCreateEventClose}>Close</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Modal onClose={onEditEventClose} isOpen={editEventOpen} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <StepperComponent 
            ref={stepper} created={() => { stepper.current.activeStep = activeModalStep }}
            stepChanged={args => setActiveModalStep(args.activeStep)}>
            <StepsDirective>
              <StepDirective disabled={!eventTypeStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Type' } />
              <StepDirective disabled={!eventDescriptionStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Description ' } />
              <StepDirective disabled={!schedulingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Scheduling' } />
              <StepDirective disabled={!communicationsStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Communication' } />
              <StepDirective disabled={!codeOfConductStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Code of Conduct' } />
              <StepDirective disabled={!pricingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Pricing' } />
              <StepDirective disabled={!confirmationStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Confirmation' } />
            </StepsDirective>
          </StepperComponent>
          <Box marginTop={5}>
            {activeModalStep === 0 && <EventType />}
            {activeModalStep === 1 && <EventDescription />}
            {activeModalStep === 2 && <Scheduling />}
            {activeModalStep === 3 && <Communications />}
            {activeModalStep === 4 && <CodeOfConduct />}
            {activeModalStep === 5 && <Pricing />}
            {activeModalStep === 6 && <Confirmation />}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={updateEvent}>Update Event</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Modal onClose={onEditInstanceClose} isOpen={editInstanceOpen} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Event Instance</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <StepperComponent 
            ref={stepper} created={() => { stepper.current.activeStep = activeModalStep }}
            stepChanged={args => setActiveModalStep(args.activeStep)}>
            <StepsDirective>
              <StepDirective disabled={!eventTypeStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Type' } />
              <StepDirective disabled={!eventDescriptionStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Event Description ' } />
              <StepDirective disabled={!schedulingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Scheduling' } />
              <StepDirective disabled={!communicationsStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Communication' } />
              <StepDirective disabled={!codeOfConductStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Code of Conduct' } />
              <StepDirective disabled={!pricingStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Pricing' } />
              <StepDirective disabled={!confirmationStepEnabled} iconCss= { 'sf-icon-user' } label= { 'Confirmation' } />
            </StepsDirective>
          </StepperComponent>
          <Box marginTop={5}>
            {activeModalStep === 0 && <EventType />}
            {activeModalStep === 1 && <EventDescription />}
            {activeModalStep === 2 && <Scheduling active={false} />}
            {activeModalStep === 3 && <Communications />}
            {activeModalStep === 4 && <CodeOfConduct />}
            {activeModalStep === 5 && <Pricing />}
            {activeModalStep === 6 && <Confirmation />}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={updateInstance}>Update Instance</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Modal onClose={() => setEventInstanceJoiningInstructionsOpen(false)} isOpen={eventInstanceJoiningInstructionsOpen} size="2xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Event Joining Instructions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EventJoiningInstructions />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setEventInstanceJoiningInstructionsOpen(false)}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Box minH={decorate !== false && "100vh"} bgGradient={decorate !== false && 'linear(to-b, gray.100, white)'}>
      {decorate !== false && <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />}
      {decorate !== false && <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer> }
      {/* mobilenav */}
      {decorate !== false && <MobileNav onOpen={onOpen} /> }
      <Box ml={decorate !== false && { base: 0, md: 60 }} p="4">
        <Text>
          <Heading as='h3' size='lg' mb={4}>Events Scheduler</Heading>
          <ScheduleComponent eventSettings={{ dataSource: eventSchedule, allowEditing: false, allowDeleting: false }} currentView='Month'>
            <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
          </ScheduleComponent>
          <br />
          <Button colorScheme='blue' onClick={async () => {
            await setEditEventInstance(null);
            onCreateEventOpen()
          }}>Create Event</Button>
          <br /><br />
          { decorate !== false && (<>
            <Heading as='h3' size='lg' mb={4}>Event Collections</Heading>
            <GridComponent dataSource={events} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
              <ColumnsDirective>
                <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                <ColumnDirective field='description' headerText='Description' width='100' textAlign='Left' />
                <ColumnDirective field='start_date' headerText='Start Date' width='100' textAlign='Left' />
                <ColumnDirective field='end_date' headerText='End Date' width='100' textAlign='Left' />
                <ColumnDirective field='venue' headerText='Venue' width='100' textAlign='Left' />
                <ColumnDirective headerText='' width='100' textAlign='Center' template={row => (
                  <Button size="xs" colorScheme='blue' onClick={() => handleEdit(row)}>Edit</Button>
                )} />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Search]}/>
            </GridComponent>
            </>)}
            </Text>
            </Box>

            <Box ml={{ base: 0, md: 60 }} p="4">
            
            { decorate !== false && <Text>
              <Heading as='h3' size='lg' mb={4}>Event Instances</Heading>
              <GridComponent dataSource={eventInstances} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
              {/* Columns */}
              <ColumnsDirective>
                <ColumnDirective field='id' headerText='ID' width='25' textAlign='Left' />
                <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                {/* <ColumnDirective field='description' headerText='Description' width='100' textAlign='Left' /> */}
                <ColumnDirective field='start_date' headerText='Start Date' width='100' textAlign='Left' />
                <ColumnDirective field='end_date' headerText='End Date' width='100' textAlign='Left' />
                {/* <ColumnDirective field='venue' headerText='Venue' width='100' textAlign='Left' /> */}
                <ColumnDirective headerText='' width='100' textAlign='Center' template={row => (<>
                  <Button size="xs" mr={2} colorScheme='blue' onClick={() => handleEditInstance(row)}>Edit</Button>
                  <Button size="xs" mr={2} colorScheme='green' onClick={async () => {                    
                    const joiningInstructions = await getEventInstanceJoiningInstructions({ event_instance_id: row.id });
                    if(joiningInstructions.length > 0) {
                      controlEventJoiningInstructions._formValues.type = joiningInstructions[0].type;
                      controlEventJoiningInstructions._formValues.instructions = joiningInstructions[0].instructions;
                      controlEventJoiningInstructions._formValues.hyperlink = joiningInstructions[0].hyperlink;

                      setEventInstanceJoiningInstructions({
                        event_instance_id: row.url,
                        ...joiningInstructions[0]
                      });
                    } else {
                      controlEventJoiningInstructions._formValues.instructions = '';
                      controlEventJoiningInstructions._formValues.hyperlink = '';

                      setEventInstanceJoiningInstructions({
                        event_instance_id: row.url,
                      })
                    }

                    setEventInstanceJoiningInstructionsOpen(true)
                  }}>Joining Instructions</Button>
                  <Button size="xs" mr={2} colorScheme='red' onClick={() => {
                    document.location.href = [portalUrl, "join/", row.id].join("");
                  }}>Joining Page</Button>
                  </>)} />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Search]}/>
            </GridComponent>
          </Text> }
      </Box>
    </Box>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </>)

  if(decorate === false) { 
    return content;
  }

  return (<DecoratePage content={content} />)
}

export default Events