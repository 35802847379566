import { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import SpeedDial from './SpeedDial'

import { logout } from '../lib/authentication';
import { getConfiguration } from '../lib/configuration';

const LinkItems = [
  { name: 'Dashboard', icon: FiHome, url: '/' },
  { name: 'Event Scheduler', icon: FiTrendingUp, url: '/events' },
  { name: 'Event Conductor', icon: FiTrendingUp, url: '/conductor' },
  { name: 'Attendees', icon: FiCompass, url: '/attendees' },
  { name: 'Conflict', icon: FiCompass, url: '/conflict' },
  { name: 'Marketing', icon: FiCompass, url: '/marketing' },
  { name: 'Automation', icon: FiCompass, url: '/automation' },
  { name: 'CMS', icon: FiStar, url: '/cms' },
  { name: 'Integrations', icon: FiStar, url: '/integrations' },
  { name: 'Settings', icon: FiStar, url: '/settings' },
  { name: 'Help', icon: FiCompass, url: '/help' }
]

export const SidebarContent = ({ onClose, ...rest }) => {
  const [ avatarUrl, setAvatarUrl ] = useState("");
  const [ isLoaded, setIsLoaded ] = useState(false);
  
  useEffect(() => {
    const fetchConfiguration = async (avatarUrl) => {
      const config = await getConfiguration();
      if(avatarUrl === "") {
        setAvatarUrl(config.logo);
      }
    };
    
    if (!isLoaded) {
      fetchConfiguration(avatarUrl);
    }
    
    setIsLoaded(true)
  }, []);

  return (<>
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          { avatarUrl.length > 3
            ? <Avatar src={avatarUrl} />
            : '...'
          }
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={e => window.location.href = link.url}>
          {link.name}
        </NavItem>
      ))}
    </Box>
    <SpeedDial />
  </>)
}

export const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

export const MobileNav = ({ onOpen, ...rest }) => {
  const [ avatarUrl, setAvatarUrl ] = useState("");
  const [ isLoaded, setIsLoaded ] = useState(false);

  const SignOut = async () => {
    await logout();
    window.location.href = '/';
  }

  useEffect(() => {
    if(!isLoaded) {
      setIsLoaded(true);
      getConfiguration()
        .then(config => {
          setAvatarUrl(config.logo);
        })
    }
  }, [isLoaded, avatarUrl])

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        {/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                { avatarUrl.length > 3
                  ? <Avatar size={'sm'} src={avatarUrl} />
                  : '...'
                }
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">Admin</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={e => window.location.href="/help"}>Help</MenuItem>
              <MenuDivider />
              <MenuItem onClick={e => window.location.href="/2fa_setup"}>Setup 2FA</MenuItem>
              <MenuDivider />
              <MenuItem onClick={SignOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}
