import { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ColumnDirective, ColumnsDirective, GridComponent, Edit, Inject, CommandColumn, Page, Search, Toolbar, endEdit, created } from '@syncfusion/ej2-react-grids';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ChartComponent } from '@syncfusion/ej2-react-charts';

import { TextBoxComponent, NumericTextBoxComponent, TextAreaComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

import { useForm, Controller, set } from "react-hook-form";

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn, getImpersonationToken } from './lib/authentication';
import {
  getProfileById, getProfiles, getProfileNotes, addProfileNote, deleteProfileNote,
  createProfileIntroduction, getProfileConflicts, createProfileConflict, updateProfileConflict,
  deleteProfileConflict } from './lib/profile';
import { getEventInstanceProfiles, getEventInstances, setAttendedEventInstanceProfile, getEventAttendees } from './lib/events';
import { getCurrentMemberships, getMemberships } from './lib/memberships';
import { getPayments, getCredits } from './lib/credits';
import { getReferrals } from './lib/referrals';
import { getProfileIntroductions } from './lib/profile';
import { FiPhone, FiPhoneCall } from 'react-icons/fi';
import { crmContactMessage } from './lib/email';

import moment from 'moment';
import { getConfiguration } from './lib/configuration';

const Attendees = ({ decorate = true }) => {
  const [ profiles, setProfiles ] = useState([]);
  const [ profilesLoaded, setProfilesLoaded ] = useState(false);

  const [ profile, setProfile ] = useState({});

  const [ profileNotes, setProfileNotes ] = useState([]);
  const [ profileNotesLoaded, setProfileNotesLoaded ] = useState(false);
  const [ newProfileNote, setNewProfileNote ] = useState('');
  const [ newProfileNoteStatus, setNewProfileNoteStatus ] = useState('');
  const [ profileEvents, setProfileEvents ] = useState([]);
  const [ profileMemberships, setProfileMemberships ] = useState([]);
  const [ profilePayments, setProfilePayments ] = useState([]);
  const [ profileCredits, setProfileCredits ] = useState([]);
  const [ profileReferrals, setProfileReferrals ] = useState([]);
  const [ profileIntroductions, setProfileIntroductions ] = useState([]);
  const [ profileConflicts, setProfileConflicts ] = useState([]);

  const [ selectedAttendee, setSelectedAttendee ] = useState(null);

  const [ impersonateModalOpen, setImpersonateModalOpen ] = useState(false);
  const [ impersonateLink, setImpersonateLink ] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const { 
    isOpen: editAttendeeOpen,
    onOpen: onEditAttendeeOpen,
    onClose: onEditAttendeeClose
  } = useDisclosure();

  const { 
    isOpen: contactAttendeeOpen,
    onOpen: onContactAttendeeOpen,
    onClose: onContactAttendeeClose
  } = useDisclosure();

  const onImpersonateClose = () => {
    setImpersonateModalOpen(false);
  }
  
  const {
    handleSubmit: handleSubmitAttendeeProfile,
    control: controlAttendeeProfile,
    watch: watchAttendeeProfile,
    register: registerAttendeeProfile,
    reset: resetAttendeeProfile,
    setValue: setValueAttendeeProfile,
    formState: { errors: errorsAttendeeProfile },
  } = useForm({
    mode: "onChange",
    defaultValues: async () => {
      return profile || {};
    }
  });

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!profilesLoaded) {
      getProfiles().then(profiles => {
        setProfiles(profiles.filter(profile => !!profile.email).map(profile => ({
          created_at: profile.created_at,
          id: profile.url.split('/')[profile.url.split('/').length - 2],
          name: profile.name,
          email: profile.email,
          company_name: profile.company_name,
          website: profile.website,
          landline: profile.landline,
          mobile: profile.mobile,
          description: profile.description,
          referral_code: profile.referral_code,
          status: profile.notes.filter(note => note.status !== "undefined").length > 0 ? profile.notes.filter(note => note.status !== "undefined")[profile.notes.filter(note => note.status !== "undefined").length - 1].status : '',
        })).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

        setProfilesLoaded(true);
      })
    }
  })

  const attendeeProfileForm = {
    name: {
      label: "Name",
      type: "text",
      placeholder: "Name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    email: {
      label: "Email",
      type: "text",
      placeholder: "Email",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    gender: {
      label: "Gender",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Male', 'Female', 'Other'],
      rules: {
        required: false,
      },
    },
    landline: {
      label: "Landline",
      type: "text",
      placeholder: "Landline",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    mobile: {
      label: "Mobile",
      type: "text",
      placeholder: "Mobile",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    country: {
      label: "Country",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['United Kingdom', 'United States', 'Canada'],
      rules: {
        required: false,
      },
    },
    dietary: {
      label: "Dietary Requirements",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Vegetarian', 'Vegan', 'Halal', 'Kosher', 'Gluten Free', 'Dairy Free', 'Nut Free', 'Shellfish Free', 'Other'],
      rules: {
        required: false,
      },
    },
    status: {
      label: "Status",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Active', 'Inactive'],
      rules: {
        required: true,
      },
    },
    industry_sectors: {
      label: "Industry Sectors",
      type: "dropdown",
      placeholder: "",
      defaultValue: "",
      options: ['Aerospace', 'Agriculture', 'Automotive', 'Banking', 'Biotechnology', 'Chemical', 'Construction', 'Consumer Goods', 'Defense', 'Education', 'Energy', 'Entertainment', 'Financial Services', 'Food & Beverage', 'Healthcare', 'Hospitality', 'Insurance', 'Manufacturing', 'Media', 'Mining', 'Pharmaceutical', 'Real Estate', 'Retail', 'Software', 'Technology', 'Telecommunications', 'Transportation', 'Utilities'],
      rules: {
        required: false,
      },
    },
    company_name: {
      label: "Company Name",
      type: "text",
      placeholder: "Company Name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    job_title: {
      label: "Job Title",
      type: "text",
      placeholder: "Job Title",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    business_description: {
      label: "Business Description",
      type: "textarea",
      placeholder: "Business Description",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    delegate_privacy: {
      label: 'Delegate Privacy',
      type: 'checkboxes',
      options: [
        'Do not appear on delegates list',
        'Remove landline',
        'Remove mobile',
        'Remove email'
      ],
      rules: {
        required: true,
      },
    },
    marketingPrivacy: {
      label: 'Marketing Privacy',
      type: 'checkboxes',
      options: [
        'Exclusive deals and promotions',
        'Announcements and breaking news',
        'Marketing emails'
      ],
      rules: {
        required: true,
      },
    }
  }

  const Input = ({ value, onChange, type, ...rest }) => {
    switch (type) {
      case "text":
        return (
          <TextBoxComponent
            placeholder={rest?.placeholder}
            disabled={!!rest?.disabled}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
        case "number":
          return (
            <NumericTextBoxComponent
              placeholder={rest?.placeholder}
              disabled={!!rest?.disabled}
              change={({ value }) => onChange(value)}
              value={value}
            />
          );

        case "textarea":
            return (
              <TextAreaComponent
              multiline={true}
              placeholder={rest?.placeholder}
              disabled={!!rest?.disabled}
              change={({ value }) => onChange(value)}
              CssClass="e-bigger"
              value={value}
              resizeMode='Vertical'
              style={{ height: 150 }}
              />
            );

      case "radio":
        return rest?.options.map((e) => (
          <RadioButtonComponent
            key={e}
            label={e}
            disabled={!!rest?.disabled}
            value={e}
            onChange={(value) => onChange(value)}
            checked={value === e}
          />
        ));
      case "dropdown":
        return (
          <DropDownListComponent
            dataSource={rest?.options}
            allowFiltering={true}
            filterType='Contains'
            placeholder={rest?.placeholder}
            enabled={!!!rest?.disabled}
            select={({ itemData }) => {
              onChange(itemData.value);
            }}
            value={value}
          />
        );
   
      case "checkbox":
        return (
          <CheckBoxComponent
            label={rest?.checkboxLabel}
            disabled={!!rest?.disabled}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        );

      case "checkboxes":
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '10px' }}>
            {rest?.options.map((e) => (
              <CheckBoxComponent
                key={e}
                label={e}
                disabled={!!rest?.disabled}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                style={{ marginBottom: '8px' }} />
            ))}
          </div>
        );
      
      case "datepicker":
        return (
          <DatePickerComponent
            label={rest?.checkboxLabel}
            disabled={!!rest?.disabled}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );

      case "timepicker":
        return (
          <TimePickerComponent
            label={rest?.checkboxLabel}
            disabled={!!rest?.disabled}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
   
      default:
        return null;
    }
  };

  const AttendeeProfileInputs = () => (Object.keys(attendeeProfileForm).map((e) => {
    const { rules, defaultValue, label } = attendeeProfileForm[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label><strong>{label}</strong></label>
        <Controller
          name={e}
          control={controlAttendeeProfile}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                disabled={true}
                {...attendeeProfileForm[e]}
              />
            </div>
          )}
        />
        {/*errorsEventDescription[e] && <Error>This field is required</Error>*/}
      </section>
    );
  }));

  const ProfileNotes = () => {
    const createProfileNote = async () => {
      if(newProfileNote.length <= 3) {
        return;
      }

      await addProfileNote(profile.id, newProfileNote, newProfileNoteStatus);
      const notes = await getProfileNotes(profile.id);
      setProfileNotes(notes);

      setNewProfileNote('');
      setNewProfileNoteStatus('');
    }

    const deleteNote = async (noteToDelete) => {
      deleteProfileNote(noteToDelete);
      const notes = await getProfileNotes(profile.id);
      setProfileNotes(notes);
    }

    const statuses = [
      '',
      'Booked A Ticket',
      'Bought A Membership',
      'Took A Subscription',
      'Appointment',
      'Telephone Appointment',
      'Hot Lead',
      'Pipeline Specific Date',
      'Pipeline',
      'Decision Maker Info Request',
      'Non-Decision Maker Info Request',
      'Not Interested',
      'Not Relevant',
      'Invalid',
      'Duplicate',
      'Call Back',
    ];

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Profile Notes</Heading>

      <Box width="100%">
      <DropDownListComponent
        dataSource={statuses}
        placeholder="Select Status"
        allowFiltering={true}
        filterType='Contains'
        change={({ value }) => setNewProfileNoteStatus(value)}
        value={newProfileNoteStatus}
      />
      </Box>

      <Box width="100%">
      <Input type="textarea" value={newProfileNote} onChange={setNewProfileNote} placeholder="Add a new note..." />
      </Box>
      
      <Button colorScheme={newProfileNote.length > 3 ? "blue": "gray"} onClick={createProfileNote}>Add Note</Button>
      
      <VStack align="start" spacing={2} width="100%">
      {profileNotes.map((note, index) => (
      <Box 
        key={index} 
        p={4} 
        borderWidth="1px" 
        borderRadius="md" 
        width="100%" 
        bgGradient="linear(to-r, gray.100, gray.200)"
      >
        <Text fontWeight="bold">{note.note}</Text>
        <Text fontSize="sm" color="gray.500">{new Date(note.created_at).toLocaleString()}</Text>
        { note.status && <Text fontSize="sm" color="gray.500">Status: {note.status}</Text> }
        <Button colorScheme="red" size="sm" onClick={() => deleteNote(note.url)}>Delete</Button>
      </Box>
      ))}
      </VStack>
      </VStack>
    )
  }

  const AttendeeEvents = () => {
    const currentDate = new Date();

    const futureEvents = profileEvents.filter(event => new Date(event.event.start_date) >= currentDate);
    const pastEvents = profileEvents.filter(event => new Date(event.event.start_date) < currentDate);

    const toggleAttendance = (url) => {
      const eventInstanceProfileId = url.split('/')[url.split('/').length - 2];

      const index = pastEvents.findIndex(event => event.url === url);

      const updatedEvents = [...pastEvents];
      updatedEvents[index].attended = !updatedEvents[index].attended;

      setAttendedEventInstanceProfile(eventInstanceProfileId, updatedEvents[index].attended);

      setProfileEvents([...futureEvents, ...updatedEvents]);
    };

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Future Events</Heading>
      <Box width="100%" overflowX="auto">
        {futureEvents.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Format</th>
          </tr>
          </thead>
          <tbody>
          {futureEvents.map((event, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.event.name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{moment(event.event.start_date).format('MMMM Do YYYY')}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.event.start_time}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.event.event_type}</td>
            </tr>
          ))}
          </tbody>
        </table>
        ) : (
        <Text>No future events.</Text>
        )}
      </Box>

      <Heading size="md" mt={8}>Past Events</Heading>
      <Box width="100%" overflowX="auto">
        {pastEvents.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Format</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Attendance</th>
          </tr>
          </thead>
          <tbody>
          {pastEvents.map((event, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.event.name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{moment(event.event.start_date).format('MMMM Do YYYY')}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.event.start_time}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.event.event_type}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <Button colorScheme={event.attended ? "green" : "red"} onClick={() => toggleAttendance(event.url)}>
              {event.attended ? "Attended" : "Not Attended"}
              </Button>
            </td>
            </tr>
          ))}
          </tbody>
        </table>
        ) : (
        <Text>No past events.</Text>
        )}
      </Box>
      </VStack>
    );
  }

  const AttendeeMemberships = () => {
    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Memberships</Heading>
      {profileMemberships.length > 0 ? (
        profileMemberships.map((membership, index) => (
        <Box
          key={index}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          width="100%"
        >
          <Text fontWeight="bold">{membership.membership.type} / {membership.membership.name}</Text>
          <Text>Start Date: {moment(membership.start_date).format('MMMM Do YYYY')}</Text>
          <Text>Start Date: {moment(membership.end_date).format('MMMM Do YYYY')}</Text>
          <Text>Description: {membership.membership.name}</Text>
        </Box>
        ))
      ) : (
        <Text>No memberships found.</Text>
      )}
      </VStack>
    );
  };

  const AttendeeConflicts = () => {
    const [signedUpEvents, setSignedUpEvents] = useState([
      { name: 'Event 1', date: '2024-10-01', time: '10:00 AM' },
      { name: 'Event 2', date: '2024-10-01', time: '10:00 AM' }, // Conflict with Event 1
      { name: 'Event 3', date: '2024-11-15', time: '02:00 PM' },
      { name: 'Event 4', date: '2024-11-15', time: '02:00 PM' }, // Conflict with Event 3
      { name: 'Event 5', date: '2024-12-05', time: '09:00 AM' },
      { name: 'Event 6', date: '2024-12-05', time: '09:00 AM' }, // Conflict with Event 5
      { name: 'Event 7', date: '2024-12-20', time: '11:00 AM' },
      { name: 'Event 8', date: '2024-01-10', time: '01:00 PM' },
      { name: 'Event 9', date: '2024-01-15', time: '10:00 AM' },
      { name: 'Event 10', date: '2024-02-20', time: '02:00 PM' },
      { name: 'Event 11', date: '2024-03-05', time: '09:00 AM' },
      { name: 'Event 12', date: '2024-04-10', time: '11:00 AM' },
      { name: 'Event 13', date: '2024-05-15', time: '01:00 PM' },
      { name: 'Event 14', date: '2024-06-20', time: '10:00 AM' },
      { name: 'Event 15', date: '2024-07-25', time: '02:00 PM' },
    ]);

    const checkConflicts = () => {
      const conflicts = [];
      const eventMap = new Map();

      signedUpEvents.forEach((event, index) => {
        const eventDateTime = `${event.date}T${event.time}`;
        if (eventMap.has(eventDateTime)) {
          conflicts.push(index);
          conflicts.push(eventMap.get(eventDateTime));
        } else {
          eventMap.set(eventDateTime, index);
        }
      });

      return conflicts;
    };

    const conflicts = checkConflicts();

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Conflicts</Heading>
      
      {conflicts.length > 0 ? (
        conflicts.map((conflictIndex, index) => (
          <Box
            key={index}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            width="100%"
            bg="red.200"
          >
            <Text fontWeight="bold">{signedUpEvents[conflictIndex].name}</Text>
            <Text>Date: {signedUpEvents[conflictIndex].date}</Text>
            <Text>Time: {signedUpEvents[conflictIndex].time}</Text>
            <Text>Conflicts With: {conflicts.filter(i => i !== conflictIndex && signedUpEvents[i].date === signedUpEvents[conflictIndex].date && signedUpEvents[i].time === signedUpEvents[conflictIndex].time).map(i => signedUpEvents[i].name).join(', ')}</Text>
          </Box>
        ))
      ) : (
        <Text>No conflicts found.</Text>
      )}

      <Box width="100%" overflowX="auto">
      {signedUpEvents.length > 0 ? (
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
        <tr>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Conflicts With</th>
        </tr>
        </thead>
        <tbody>
        {signedUpEvents.map((event, index) => {
        const conflictIndexes = conflicts.filter(conflictIndex => conflictIndex !== index && signedUpEvents[conflictIndex].date === event.date && signedUpEvents[conflictIndex].time === event.time);
        return (
          <tr key={index} style={{ backgroundColor: conflictIndexes.length > 0 ? 'red' : 'green' }}>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.name}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.date}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{event.time}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            {conflictIndexes.length > 0 ? conflictIndexes.map(conflictIndex => signedUpEvents[conflictIndex].name).join(', ') : 'None'}
          </td>
          </tr>
        );
        })}
        </tbody>
      </table>
      ) : (
      <Text>No events.</Text>
      )}
      </Box>
      </VStack>
    );
  };

  const AttendeePayments = () => {
    const totalPayments = profilePayments.reduce((sum, payment) => sum + Number(payment.value), 0);

    const ucFirst = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
      <VStack align="start" spacing={4}>
      <Heading size="md">Payments</Heading>
      
      <Box mt={4} p={4} borderWidth="1px" borderRadius="md" width="100%" bg="gray.100">
      <Text><strong>Total Payments:</strong> £{totalPayments}</Text>
      </Box>

      <Box width="100%" overflowX="auto">
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Provider</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Type</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Amount (£)</th>
        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Notes</th>
        </tr>
      </thead>
      <tbody>
        {profilePayments.map((payment, index) => (
        <tr key={index}>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{moment(payment.created_at).format('MMMM Do YYYY')}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{ucFirst(payment.provider)}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{ucFirst(payment.type)}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{payment.value}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{payment.notes || 'N/A'}</td>
        </tr>
        ))}
      </tbody>
      </table>
      </Box>
      </VStack>
    );
  };

  const AttendeeReferrals = () => {
    const [referrals, setReferrals] = useState([
      { date: '2023-01-01', name: 'John Doe' },
      { date: '2023-02-15', name: 'Jane Smith' },
      { date: '2023-03-10', name: 'Alice Johnson' },
      { date: '2023-04-05', name: 'Bob Brown' },
    ]);

    const totalCredits = referrals.length;

    return (
      <VStack align="start" spacing={4}>
        <Heading size="md">Referrals</Heading>
        
        <Box mt={4} p={4} borderWidth="1px" borderRadius="md" width="100%" bg="gray.100">
          <Text><strong>Referral Code:</strong> {profile.referral_code}</Text>
          <Text><strong>Total Credits Gained:</strong> {profileCredits.reduce((sum, credit) => sum + Number(credit.amount), 0)}</Text>
        </Box>

        <Box width="100%" overflowX="auto">
          {referrals.length > 0 ? (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
                </tr>
              </thead>
              <tbody>
                {profileReferrals.map((referral, index) => (
                  <tr key={index}>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{moment(referral.created_at).format('MMMM Do YYYY')}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{referral.referral_name}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{referral.referral_email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Text>No referrals found.</Text>
          )}
        </Box>
      </VStack>
    );
  };

  const AttendeeIntros = () => {
    const [ selectedEvent, setSelectedEvent ] = useState('');
    const [ eventAttendees, setEventAttendees ] = useState([]);
    const [ selectedAttendee, setSelectedAttendee ] = useState('');
    const [ selectedId, setSelectedId ] = useState('');

    const [messageToAttendee, setMessageToAttendee] = useState('');
    const [messageToIntroduced, setMessageToIntroduced] = useState('');

    const handleEventChange = async (event) => {
      setSelectedEvent(event.value);
      setSelectedAttendee('');
      const profiles = await getEventAttendees( { event_instance_id: event.itemData.value } );
      
      const profileList = await Promise.all(profiles.map(async profile => {
        const profileData = await getProfileById(profile.profile_id.split('/')[profile.profile_id.split('/').length - 2]);
        return {
          text: profileData.name,
          value: profile.profile_id.split('/')[profile.profile_id.split('/').length - 2]
        }
      }));

      setEventAttendees(profileList);
    };

    const handleAttendeeChange = (attendee) => {
      setSelectedAttendee(attendee.value);
      setSelectedId(attendee.itemData.value);
    };

    const handleSubmit = async () => {
      if (selectedEvent && selectedAttendee) {
        const alreadyIntroduced = profileIntroductions.some(intro => intro.profile_id_attendee === selectedId && intro.profile_id_introduced === profile.id);

        if (!alreadyIntroduced) { 
          await createProfileIntroduction({
            profile_id_attendee: selectedId,
            profile_id_introduced: profile.id,
            message_attendee: messageToAttendee,
            message_introduced: messageToIntroduced
          });
          
          const profileIntroductions = await getProfileIntroductions({ profile_id: profile.id });
          const profileIntroductionsWithDetails = await Promise.all(profileIntroductions.map(async profileIntroduction => {
            const profileAttendee = await getProfileById(profileIntroduction.profile_id_attendee);
            const profileIntroduced = await getProfileById(profileIntroduction.profile_id_introduced);
            return {
              ...profileIntroduction,
              profileAttendee,
              profileIntroduced
            };
          }));
          setProfileIntroductions(profileIntroductionsWithDetails);

          // TODO: Send email to attendee

          setSelectedEvent('');
          setSelectedAttendee('');
        } else {
          alert('This introduction has already been made.');
        }
      }
    };

    const totalEventsAttended = profileEvents.filter(event => event.attended).length;
    const totalIntroductions = profileIntroductions.length;

    return (
      <VStack align="start" spacing={4}>
        <Heading size="md">Introductions</Heading>
        
        <Box mt={4} p={4} borderWidth="1px" borderRadius="md" width="100%" bg="gray.100">
          <Text><strong>Total Events Attended:</strong> {totalEventsAttended}</Text>
          <Text><strong>Total Introductions Made:</strong> {totalIntroductions}</Text>
        </Box>

        <Box width="100%">
          <DropDownListComponent
            dataSource={profileEvents.map(event => {
              return {
                text: `${moment(event.event.start_date).format('MMMM Do YYYY')} - ${event.event.name}`,
                value: event.event_instance_id.split('/')[event.event_instance_id.split('/').length - 2]
              };
            })}
            placeholder="Select Event"
            change={handleEventChange}
            value={selectedEvent}
            allowFiltering={true}
            filterType='Contains'
            data-bs-focus={false}
          />
        </Box>

        {selectedEvent && (
          <Box width="100%">
            <DropDownListComponent
              dataSource={eventAttendees}
              placeholder="Select Attendee"
              change={handleAttendeeChange}
              value={selectedAttendee}
            />
          </Box>
        )}

        {selectedEvent && selectedAttendee && (
          <Box width="100%">
            <TextAreaComponent
              placeholder="Message to Attendee"
              multiline={true}
              value={messageToAttendee}
              change={({ value }) => setMessageToAttendee(value)}
              style={{ height: 150 }}
              resizeMode='Vertical'
            />
          </Box>
        )}

        {selectedEvent && selectedAttendee && (
          <Box width="100%">
            <TextAreaComponent
              placeholder="Message to Introduced"
              multiline={true}
              value={messageToIntroduced}
              change={({ value }) => setMessageToIntroduced(value)}
              style={{ height: 150 }}
              resizeMode='Vertical'
            />
          </Box>
        )}

        <Button colorScheme="blue" onClick={handleSubmit}>Make Introduction</Button>

        <VStack align="start" spacing={2} width="100%">
          {profileIntroductions.map((intro, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" width="100%">
              <Text><strong>Person</strong>: {intro.profileAttendee.name}</Text>
              <Text><strong>Introduced to</strong>: {intro.profileIntroduced.name}</Text>
              <Text><strong>Message for Person</strong>: {intro.message_attendee.split(0, 50) + '...'}</Text>
              <Text><strong>Message for Introduced</strong>: {intro.message_introduced.split(0, 50) + '...'}</Text>
              <Text><strong>Created At</strong>: {moment(intro.created_at).format('MMMM Do YYYY')}</Text>
            </Box>
          ))}
        </VStack>
      </VStack>
    );
  };

  const CodeOfConduct = () => {
    const [newConflict, setNewConflict] = useState({
      id: '',
      name: '',
      startedBy: '',
      resolved: false,
      description: ''
    });

    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      setNewConflict({
        ...newConflict,
        [name]: type === 'checkbox' ? checked : value
      });
    };

    const handleAddConflict = async () => {
      if(!!newConflict.id) {
        await updateProfileConflict({
          id: newConflict.id,
          resolved: newConflict.resolved,
          started_by: newConflict.startedBy,
          description: newConflict.description
        });
        
        const profileConflicts = await getProfileConflicts({ profile_id: profile.id });
        setProfileConflicts(profileConflicts);
      } else {
        const conflict = await createProfileConflict({
          profile_id: profile.id,
          profile_id_conflict: newConflict.name,
          type: 'profile',
          resolved: newConflict.resolved,
          started_by: newConflict.startedBy,
          description: newConflict.description,
        });
        
        const profileConflicts = await getProfileConflicts({ profile_id: profile.id });
        setProfileConflicts(profileConflicts);
      }

      setNewConflict({
        name: '',
        startedBy: '',
        resolved: false,
        description: ''
      });
    };

    const handleEditConflict = (conflict) => {
      const profileName = profiles.find(profile => profile.id == conflict.profile_id_conflict).name;
      setNewConflict({
        id: conflict.id,
        name: profileName,
        startedBy: conflict.started_by,
        resolved: conflict.resolved,
        description: conflict.description
      });
    };

    const handleDeleteConflict = async (conflict) => {
      deleteProfileConflict(conflict);

      setProfileConflicts(profileConflicts.filter(c => c.id !== conflict.id));
    };

    return (
      <VStack align="start" spacing={6} width="100%" p={4}>
      <Heading size="md">Manage Conflicts</Heading>
      <Box width="100%">
        { profiles.length === 0 && <Text>Loading...</Text> }
        { profiles.length > 0 && <DropDownListComponent
          dataSource={profiles.map(profile => ({
            text: profile.name,
            value: profile.id
          }))}
          allowFiltering={true}
          filterType='Contains'
          placeholder="Select Attendee"
          change={(e) => setNewConflict({ ...newConflict, name: e.itemData.value })}
          value={newConflict.name}
        /> }
      </Box>
      <Box width="100%">
        <DropDownListComponent
          dataSource={['this person', 'the other person', 'unknown', 'it\'s complicated']}
          placeholder="Started By"
          allowFiltering={true}
          filterType='Contains'
          change={({ value }) => setNewConflict({ ...newConflict, startedBy: value })}
          value={newConflict.startedBy}
        />
      </Box>
      <Box width="100%">
        <CheckBoxComponent
        label="Resolved"
        name="resolved"
        checked={newConflict.resolved}
        onChange={(e) => setNewConflict({ ...newConflict, resolved: e.target.checked })}
        />
      </Box>
      <Box width="100%">
        <TextAreaComponent
        placeholder="Description"
        name="description"
        value={newConflict.description}
        change={({ value }) => handleInputChange({ target: { name: 'description', value } })}
        style={{ height: 150 }}
        resizeMode='Vertical'
        />
      </Box>
      <Button colorScheme="blue" onClick={handleAddConflict}>{ !!newConflict.id ? 'Update Conflict' : 'New Conflict' }</Button>
      <VStack align="start" spacing={4} width="100%">
        {profileConflicts.map((conflict, index) => (
        <Box 
          key={index} 
          p={4} 
          borderWidth="1px" 
          borderRadius="md" 
          width="100%" 
          bgGradient={conflict.resolved ? "linear(to-r, green.100, green.200)" : "linear(to-r, red.100, red.200)"}
          mb={4}
        >
          <Text><strong>Name:</strong> {profiles.find(profile => profile.id == conflict.profile_id_conflict).name}</Text>
          <Text><strong>Started By:</strong> {conflict.started_by}</Text>
          <Text><strong>Resolved:</strong> {conflict.resolved ? 'Yes' : 'No'}</Text>
          <Text><strong>Description:</strong> {conflict.description}</Text>
          <HStack spacing={4} mt={4}>
          <Button colorScheme="yellow" onClick={() => handleEditConflict(conflict)}>Edit</Button>
          <Button colorScheme="red" onClick={() => handleDeleteConflict(conflict)}>Delete</Button>
          </HStack>
        </Box>
        ))}
      </VStack>
      </VStack>
    );
  };

  const managementTabs = [
    {
      header: {
        text: 'Profile'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeProfileInputs />
        </Box>
      }),
    },
    {
      header: {
        text: 'Notes'
      },
      content: (() => {
        return <Box m={2}>
          <ProfileNotes />
        </Box>
      }),
    },
    {
      header: {
        text: 'Events'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeEvents />
        </Box>
      }),
    },
    {
      header: {
        text: 'Membership'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeMemberships />
        </Box>
      }),
    },
    // {
    //   header: {
    //     text: 'Conflict'
    //   },
    //   content: (() => {
    //     return <Box m={2}>
    //       <AttendeeConflicts />
    //     </Box>
    //   }),
    // },
    {
      header: {
        text: 'Payments'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeePayments />
        </Box>
      }),
    },
    {
      header: {
        text: 'Referrals'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeReferrals />
        </Box>
      }),
    },
    {
      header: {
        text: 'Introductions'
      },
      content: (() => {
        return <Box m={2}>
          <AttendeeIntros />
        </Box>
      }),
    },
    {
      header: {
        text: 'Code of Conduct'
      },
      content: (() => {
        return <Box m={2}>
          <CodeOfConduct />
        </Box>
      }),
    }
  ];

  const gridCommandClick = async (args) => {
    const { commandColumn, rowData } = args;
    const { commandHandler, buttonOption } = commandColumn;
    const { content } = buttonOption;

    if(content === 'Details') {
      setProfile(rowData);
      
      Object.keys(profile).forEach(key => {
        setValueAttendeeProfile(key, profile[key]);
      });

      // set profile notes
      const notes = await getProfileNotes(rowData.id);
      setProfileNotes(notes);

      const profileEventInstances = await getEventInstanceProfiles({ profile_id: rowData.id });

      const eventInstances = await getEventInstances();
      console.log({eventInstances});
      const profileEventInstancesWithEvents = profileEventInstances.map(profileEventInstance => {
        return {
          ...profileEventInstance,
          event: profileEventInstance.event_instance_id ? eventInstances.find(eventInstance => eventInstance.url === profileEventInstance.event_instance_id) : {}
        };
      }).sort((a, b) => {
        const aStartDate = new Date(a.event.start_date);
        const bStartDate = new Date(b.event.start_date);
        const aStartTime = new Date(a.event.start_time);
        const bStartTime = new Date(b.event.start_time);

        if (aStartDate < bStartDate) {
          return -1;
        } else if (aStartDate > bStartDate) {
          return 1;
        } else {
          if (aStartTime < bStartTime) {
            return -1;
          } else if (aStartTime > bStartTime) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      setProfileEvents(profileEventInstancesWithEvents);

      const profileMemberships = await getCurrentMemberships({ profile_id: rowData.id });
      const memberships = await getMemberships();
      
      const profileMembershipsWithDetails = profileMemberships.map(profileMembership => {
        const membership = memberships.find(membership => membership.url.split('/')[membership.url.split('/').length - 2] === profileMembership.membership_id.split('/')[profileMembership.membership_id.split('/').length - 2]);
        return {
          ...profileMembership,
          membership: membership
        };
      });

      setProfileMemberships(profileMembershipsWithDetails);

      const profilePayments = await getPayments({ profile_id: rowData.id });
      setProfilePayments(profilePayments);

      const profileCredits = await getCredits({ profile_id: rowData.id });
      setProfileCredits(profileCredits);

      const profileReferrals = await getReferrals({ profile_id: rowData.id });
      setProfileReferrals(profileReferrals);

      const profileIntroductions = await getProfileIntroductions({ profile_id: rowData.id });
      const profileIntroductionsWithDetails = await Promise.all(profileIntroductions.map(async profileIntroduction => {
        const profileAttendee = await getProfileById(profileIntroduction.profile_id_attendee);
        const profileIntroduced = await getProfileById(profileIntroduction.profile_id_introduced);
        return {
          ...profileIntroduction,
          profileAttendee,
          profileIntroduced
        };
      }));
      setProfileIntroductions(profileIntroductionsWithDetails);

      const profileConflicts = await getProfileConflicts({ profile_id: rowData.id });
      setProfileConflicts(profileConflicts);

      onEditAttendeeOpen();
    }

    if(content === 'Contact') {
      setSelectedAttendee(rowData);
      onContactAttendeeOpen();
    }

    if(content === 'Impersonate') {
      const impersonateToken = await getImpersonationToken({ profile_id: rowData.id });
      
      const { portal_url } = await getConfiguration();
      
      document.location.href = `${portal_url}impersonate/${impersonateToken}`;
    }

    if(content === 'Impersonate Link') {
      const impersonateToken = await getImpersonationToken({ profile_id: rowData.id });
      
      const { portal_url } = await getConfiguration();
      
      setImpersonateLink(`${portal_url}impersonate/${impersonateToken}`);
      setImpersonateModalOpen(true);
    }
  }

  const toolbarOptions = ['Search'];
  const searchOptions = {
      fields: ['name', 'email', 'companyName', 'website', 'description'],
      ignoreCase: true,
      operator: 'contains'
  };

  const ContactAttendeeModal = ({ attendee }) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSendMessage = () => {
      if (message.trim().length > 0) {
        addProfileNote(attendee.id, `Email Sent: ${message}`);

        // Send Email
        crmContactMessage({email: attendee.email, subject, message});

        alert(`Message sent to ${attendee.name}`);
        setMessage('');
        onContactAttendeeClose();
      }
    };

    return (
      <VStack align="start" spacing={4} width="100%">
        <Heading size="md">Contact {attendee.name}</Heading>
        <Text><strong>Email:</strong> {attendee.email}</Text>
        { !!attendee.company_name && <Text><strong>Company:</strong> {attendee.company_name}</Text> }
        <HStack spacing={4} width="100%">
          {attendee.mobile && (
            <Button
              leftIcon={<Icon as={FiPhone} />}
              colorScheme="teal"
              onClick={() => alert(`Calling ${attendee.mobile}`)}
              width="100%"
            >
              Call Mobile: {attendee.mobile}
            </Button>
          )}
          {attendee.landline && (
            <Button
              leftIcon={<Icon as={FiPhoneCall} />}
              colorScheme="teal"
              onClick={() => alert(`Calling ${attendee.landline}`)}
              width="100%"
            >
              Call Landline: {attendee.landline}
            </Button>
          )}
        </HStack>
        <TextBoxComponent
          placeholder="Subject"
          change={({ value }) => setSubject(value)}
          value={subject}
        />

        <TextAreaComponent
          placeholder="Type your message here..."
          multiline={true}
          value={message}
          change={({ value }) => setMessage(value)}
          CssClass="e-bigger"
          style={{ height: 150 }}
          resizeMode='Vertical'
        />
        <Button colorScheme="blue" onClick={handleSendMessage} width="100%">Send Message</Button>
      </VStack>
    );
  };

  const DecoratePage = ({ content }) => {
    return (<>
      <ChakraProvider theme={theme}>
        {content}
      </ChakraProvider>
    </>)
  }

  const content = (<>
    <Modal onClose={onImpersonateClose} isOpen={impersonateModalOpen} size="2xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Impersonate Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{impersonateLink}</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onImpersonateClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Modal onClose={onEditAttendeeClose} isOpen={editAttendeeOpen} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Attendee</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TabComponent>
            <TabItemsDirective>
              { managementTabs.map((tab, index) => (
                <TabItemDirective header={tab.header} content={tab.content} key={index}/>
              )) }
            </TabItemsDirective>
          </TabComponent>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onEditAttendeeClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Modal onClose={onContactAttendeeClose} isOpen={contactAttendeeOpen} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contact Attendee</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ContactAttendeeModal key={selectedAttendee} attendee={selectedAttendee} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onContactAttendeeClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Box minH="100vh" bg={useColorModeValue(
      decorate ? 'gray.100' : 'white',
      decorate ? 'gray.900' : 'white'
    )}>
      {decorate && <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />}
      {decorate && <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>}
      {/* mobilenav */}
      {decorate && <MobileNav onOpen={onOpen} />}
      <Box p="4" ml={{ base: 0, md: decorate ? '250px' : '0' }}>
        <Text>
        <Heading as='h3' size='lg' mb={4}>Attendees</Heading>
          <br />
          <GridComponent dataSource={profiles} commandClick={gridCommandClick} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
            <ColumnsDirective>
              <ColumnDirective width={75} field='name' headerText='Name' textAlign='Left'></ColumnDirective>
              <ColumnDirective width={75} field='email' headerText='Email' textAlign='Left'></ColumnDirective>
              <ColumnDirective width={75} field='company_name' headerText='Company Name' textAlign='Left'></ColumnDirective>
              <ColumnDirective width={75} field='website' headerText='Website' textAlign='Left'></ColumnDirective>
              <ColumnDirective width={75} field='status' headerText='Status' textAlign='Left'></ColumnDirective>
              {/* <ColumnDirective field='landline' headerText='Landline' textAlign='Left'></ColumnDirective> */}
              { true && <ColumnDirective width={200} textAlign='Right' commands={[
                  { buttonOption: { content: 'Details', cssClass: 'e-flat' } },
                  { buttonOption: { content: "Contact", cssClass: 'e-flat' } },
                  { buttonOption: { content: "Impersonate", cssClass: 'e-flat' } },
                  { buttonOption: { content: "Impersonate Link", cssClass: 'e-flat' } }
              ]}></ColumnDirective> }
            </ColumnsDirective>
            <Inject services={[Edit, CommandColumn, Page, Toolbar, Search]}/>
          </GridComponent>
        </Text>
      </Box>
    </Box>
  </>)

  if (decorate) {
    return <DecoratePage content={content} />
  }

  return content;
}

export default Attendees