import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, TrendlineDirective, TrendlinesDirective, Inject, Tooltip, LineSeries, ScatterSeries, SplineSeries, Trendlines, Category, DateTime } from '@syncfusion/ej2-react-charts';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import Conflict from './Conflict';
import Events from './Events';
import Attendees from './Attendees';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ statusLoaded, setStatusLoaded ] = useState(false);

  const [ contactsCreated, setContactsCreated ] = useState([]);
  const [ contactsUpdated, setContactsUpdated ] = useState([]);
  const [ contactsDeleted, setContactsDeleted ] = useState([]);

  const [ gridData, setGridData ] = useState([]);

  const primaryxAxis = { valueType: 'Category', intervalType: 'Days', edgeLabelPlacement: 'Shift', majorGridLines: { width: 0 } };
  const primaryyAxis = {
      title: 'Contacts Changed', interval: 25,
      lineStyle: { width: 0 }, majorTickLines: { width: 0 }
  };
  const tooltip = { enable: true };
  const chartarea = { border: { width: 0 } };
  const marker = { visible: false };

  let cellSpacing = [4, 4];

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    getMarketingStatus().then(status => {
        if (statusLoaded) {
          return;
        }

        const _contactsCreated = [];
        const _contactsUpdated = [];
        const _contactsDeleted = [];

        status.data.syncs.reverse().forEach((sync, key) => {
          _contactsCreated.push({ x: moment(sync.created_at).format('YYYY-MM-DD HH:mm:ss'), y: sync.contacts_created });
          _contactsUpdated.push({ x: moment(sync.created_at).format('YYYY-MM-DD HH:mm:ss'), y: sync.contacts_updated });
          _contactsDeleted.push({ x: moment(sync.created_at).format('YYYY-MM-DD HH:mm:ss'), y: sync.contacts_updated });
        });

        setContactsCreated(_contactsCreated);
        setContactsUpdated(_contactsUpdated);
        setContactsDeleted(_contactsDeleted);

        setGridData(status.data.contacts);

        setStatusLoaded(true);
      });
  })

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <div className="control-section">
            <Heading as='h3' size='lg' mb={4}>Dashboard</Heading>
            <DashboardLayoutComponent id='defaultLayout' cellSpacing={cellSpacing} allowResizing={true} columns={2}>
              <Box className="e-panel" p={4} data-row="0" data-col="0" data-sizex="1" data-sizey="1">
                <Events decorate={false} />
              </Box>
              {/* <Box className="e-panel" p={4} data-row="0" data-col="1" data-sizex="1">
                <Heading as='h4' size='md' mb={4}>Attendees</Heading>
              </Box>
              <Box className="e-panel" p={4} data-row="1" data-col="0" data-sizex="1">
                <Heading as='h4' size='md' mb={4}>Integrations</Heading>
              </Box> */}
              <Box className="e-panel" p={4} data-row="1" data-col="1" data-sizex="1" data-sizey="1">
                <Conflict decorate={false} />
              </Box>
              <Box className="e-panel" p={4} data-row="2" data-col="0" data-sizex="2" data-sizey="2">
                <Attendees decorate={false} />
              </Box>
            </DashboardLayoutComponent>
          </div>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default App