import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  Box,
  VStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Heading,
  theme
} from '@chakra-ui/react'

import { useToast } from '@chakra-ui/react';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';
import { bootstrapBrevo, getConfiguration, updateIntegrationConfiguration, bootstrapStripe, brevoOk as isBrevoOK, stripeOk } from './lib/configuration';
import { getPages, createPage, updatePage, deletePage } from './lib/cms';
import { FormControl, FormLabel, Input, Button } from '@chakra-ui/react';

const Integrations = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const [ globalSettings, setGlobalSettings ] = useState({
    portal_url: '',
  });

  const [ brevoAPIKey, setBrevoAPIKey ] = useState('');
  const [ stripeAPIKey, setStripeAPIKey ] = useState('');
  const [ stripePrivateAPIKey, setStripePrivateAPIKey ] = useState('');

  const [ brevoOK, setBrevoOK ] = useState(false);
  const [ stripeOK, setStripeOK ] = useState(false);
  
  const [ loaded, setLoaded ] = useState(false);

  const toast = useToast();

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!loaded) {
      getConfiguration().then(settings => {
        setGlobalSettings(settings);
      });

      isBrevoOK().then(ok => {
        setBrevoOK(ok);
      });

      stripeOk().then(ok => {
        setStripeOK(ok);
      });

      setLoaded(true);
    }
  })

  const handleBrevoAPIKeyChange = (e) => {
    setBrevoAPIKey(e.target.value);
  };

  const handleBrevoApiKeySubmit = async () => {
    const update = await updateIntegrationConfiguration({ brevo_api_key: brevoAPIKey });
    const bootstrap = await bootstrapBrevo();

    if(!!update && !!bootstrap) {
      toast({
        title: "Brevo API Key Updated",
        description: "The Brevo API Key has been updated successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setBrevoOK(true);
    } else {
      toast({
        title: "Brevo API Key Update Failed",
        description: "The Brevo API Key could not be updated.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleStripeAPIKeyChange = (e) => {
    setStripeAPIKey(e.target.value);
  }

  const handleStripePrivateAPIKeyChange = (e) => {
    setStripePrivateAPIKey(e.target.value);
  }

  const handleStripeApiKeySubmit = async () => {
    const update = await updateIntegrationConfiguration({ stripe_api_key: stripeAPIKey, stripe_private_api_key: stripePrivateAPIKey });
    const bootstrap = await bootstrapStripe();

    if(!!update && !!bootstrap) {
      toast({
        title: "Stripe API Key Updated",
        description: "The Stripe API Key has been updated successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setStripeOK(true);
    } else {
      toast({
        title: "Stripe API Key Update Failed",
        description: "The Stripe API Key could not be updated.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>Integrations</Heading>
          <Box>
            <VStack spacing={4} align="stretch">
              <Box mt={2}>
                <Heading as='h3' size='md'>Brevo</Heading>
                <FormControl id="api-key">
                  <FormLabel>API Key</FormLabel>
                  <Input type="text" value={brevoAPIKey} onChange={handleBrevoAPIKeyChange} style={{ backgroundColor: '#FFF' }} />
                  <Button mt={2} colorScheme="teal" onClick={handleBrevoApiKeySubmit}>
                    Save
                  </Button>
                  <Box mt={2}>
                    <strong>Brevo Webhook URL</strong>: {globalSettings.portal_url.replace('portal', 'brevo-marketing.services')}api/v1/feedback
                  </Box>
                  <Box mt={2}>
                    <strong>Status</strong>: {brevoOK ? 'OK' : 'Not Configured'}
                  </Box>
                </FormControl>
              </Box>
              <Box>
                <Heading as='h3' size='md'>Stripe</Heading>
                <FormControl id="api-key">
                  <FormLabel>API Key</FormLabel>
                  <Input type="text" value={stripeAPIKey} onChange={handleStripeAPIKeyChange} style={{ backgroundColor: '#FFF' }} />
                  <FormLabel>Private API Key</FormLabel>
                  <Input type="text" value={stripePrivateAPIKey} onChange={handleStripePrivateAPIKeyChange} style={{ backgroundColor: '#FFF' }} />
                  <Button mt={2} colorScheme="teal" onClick={handleStripeApiKeySubmit}>
                    Save
                  </Button>
                  <Box mt={2}>
                    <strong>Stripe Webhook URL</strong>: {globalSettings.portal_url.replace('portal', 'payments-stripe.services')}api/v1/webhook
                  </Box>
                  <Box mt={2}>
                    <strong>Status</strong>: {stripeOK ? 'OK' : 'Not Configured'}
                  </Box>
                </FormControl>
              </Box>
            </VStack>
          </Box>
          <Box mt={4}></Box>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default Integrations