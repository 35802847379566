import { getAuthToken, setAuthToken, removeAuthToken } from "./browser_state";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const getProfiles = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profiles/?all=true`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const profiles = await response.json();

    return profiles;
}

export const getProfile = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profiles/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const profiles = await response.json();

    return profiles[0];
}

export const getProfileById = async (id) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profiles/${id}/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const profile = await response.json();

    return profile;
}

export const createProfile = async ({ name, account_id, description }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("account_id", `${base_url}/api/users/${account_id}/`);
    formdata.append("description", description);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profiles/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
};

export const updateProfile = async ({
    name,
    logo,
    contact_email,
    company_name,
    description,
    landline,
    mobile,
    email,
    website,
    address_line_1,
    address_line_2,
    city,
    country,
    county,
    postcode,
    gender,
    industry,
    dietary_requirements,
    job_title
}) => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const formdata = new FormData();
    if(!!name) formdata.append("name", name);
    if(!!logo) formdata.append("logo", logo);
    if(!!contact_email) formdata.append("contact_email", contact_email);
    if(!!company_name) formdata.append("company_name", company_name);
    if(!!description) formdata.append("description", description);
    if(!!landline) formdata.append("landline", landline);
    if(!!mobile) formdata.append("mobile", mobile);
    if(!!email) formdata.append("email", email);
    if(!!website) formdata.append("website", website);
    if(!!address_line_1) formdata.append("address_line_1", address_line_1);
    if(!!address_line_2) formdata.append("address_line_2", address_line_2);
    if(!!city) formdata.append("city", city);
    if(!!country) formdata.append("country", country);
    if(!!county) formdata.append("county", county);
    if(!!postcode) formdata.append("postcode", postcode);
    if(!!gender) formdata.append("gender", gender);
    if(!!industry) formdata.append("industry", industry);
    if(!!dietary_requirements) formdata.append("dietary_requirements", dietary_requirements);
    if(!!job_title) formdata.append("job_title", job_title);

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profiles/${profileId}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileSocials = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_socials/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const socials = await response.json();

    return socials;
}

export const addProfileSocial = async ({ name, url }) => {
    const profile = await getProfile();
    const profile_id = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("profile_id", `${base_url}/api/profiles/${profile_id}/`);
    formdata.append("name", name);
    formdata.append("url", url);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_socials/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const updateProfileSocial = async ({ id, name, url }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const formdata = new FormData();
    if(!!name) formdata.append("name", name);
    if(!!url) formdata.append("url", url);

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_socials/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteProfileSocial = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_socials/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileMarketingPreferences = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_marketing_preferences/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const socials = await response.json();

    return socials;
}

export const deleteProfileMarketingPreferences = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_marketing_preferences/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileSubscriptions = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_marketing_preferences/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const socials = await response.json();

    return socials;
}

export const deleteProfileSubscriptions = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_marketing_preferences/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileIndustries = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/dictionary_industry/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const industries = await response.json();

    return industries.results;
}

export const createProfileIndustry = async ({ name, description }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/dictionary_industry/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteProfileIndustry = async ({ id }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/dictionary_industry/${id}/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileSubscriptionTypes = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/dictionary_subscription_types/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const subscriptionTypes = await response.json();

    return subscriptionTypes.results;
}

export const createProfileSubscriptionType = async ({ name, description, price, duration }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("price", price);
    formdata.append("duration", duration);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/dictionary_subscription_types/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteProfileSubscriptionType = async ({ id }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/dictionary_subscription_types/${id}/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileNotes = async (id) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_notes/?profile_id=${id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const notes = await response.json();

    return notes;
}

export const addProfileNote = async (profile_id, note, status) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("profile_id", `${base_url}/api/profiles/${profile_id}/`);
    formdata.append("note", note);
    formdata.append("status", status);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_notes/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteProfileNote = async (url) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getMemberships = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/memberships/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const memberships = await response.json();

    return memberships.results;
}

export const createMembership = async ({ name, type, description, cost, monthly_credits, event, product_id }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("cost", cost);
    formdata.append("monthly_credits", monthly_credits);
    formdata.append("type", type);
    formdata.append("product_id", product_id);
    
    if(!!event) formdata.append("event", event);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/memberships/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteMembership = async ({ id }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/memberships/${id}/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const toggle2faOnProfile = async () => {
    // profile_id is logged in user's profile
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    if(profile.enabled_2fa) {
        formdata.append("enabled_2fa", false);
    } else {
        formdata.append("enabled_2fa", true);
    }

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profiles/${profileId}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const get2FAQRCode = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/2fa`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const qrCode = await response.blob();
    const arrayBuffer = await qrCode.arrayBuffer();
    
    return arrayBufferToBase64(arrayBuffer);
}

export const is2faEnabled = async (username) => {
    const response_username = await fetch(`${base_url}/api/2faenabled?username=${username}`);
    if(response_username.status === 200) {
        const response_metadata = await response_username.json();
        return response_metadata.enabled;
    }
    
    const response_email = await fetch(`${base_url}/api/2faenabled?email=${username}`);
    if(response_email.status === 200) {
        const response_metadata = await response_email.json();
        return response_metadata.enabled;
    }

    return false;
}

export const getProfileIntroductions = async ({ profile_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_introductions/?profile_id=${profile_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const introductions = await response.json();

    return introductions;
}

export const createProfileIntroduction = async ({ profile_id_attendee, profile_id_introduced, message_attendee, message_introduced }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("profile_id_attendee", profile_id_attendee);
    formdata.append("profile_id_introduced", profile_id_introduced);
    formdata.append("message_attendee", message_attendee);
    formdata.append("message_introduced", message_introduced);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_introductions/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getProfileConflicts = async ({ profile_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_conflicts/?profile_id=${profile_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const conflicts = await response.json();

    return conflicts;
}

export const createProfileConflict = async ({ profile_id, profile_id_conflict, type, started_by, resolved, description }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("profile_id", profile_id);
    formdata.append("profile_id_conflict", profile_id_conflict);
    formdata.append("type", type);
    formdata.append("started_by", started_by);
    formdata.append("resolved", resolved);
    formdata.append("description", description);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_conflicts/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const updateProfileConflict = async ({ id, type, started_by, description, resolved }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const formdata = new FormData();
    if(!!type) formdata.append("type", type);
    if(!!started_by) formdata.append("started_by", started_by);
    if(!!description) formdata.append("description", description);
    if(typeof resolved !== 'undefined') {
        formdata.append("resolved", resolved);
    }

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_conflicts/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteProfileConflict = async ({ id }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_conflicts/${id}/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

// getJobTitles, createJobTitle, deleteJobTitle
export const getJobTitles = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_job_titles/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const jobTitles = await response.json();

    return jobTitles.results;
}

export const createJobTitle = async ({ name, description }) => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_job_titles/`, requestOptions);
        const data = await response.json();
        
        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deleteJobTitle = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_job_titles/${id}/`, requestOptions);
        
        return response;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}
