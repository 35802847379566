import { getAuthToken } from './browser_state';
import { getProfile } from './profile';

let PORTAL_API_URL = '{{ settings.PORTAL_API_URL }}';
let MANAGEMENT_API_URL = '{{ settings.MANAGEMENT_API_URL }}';

export const globalSettings = {
    PORTAL_API_URL: PORTAL_API_URL == '{{ settings.PORTAL_API_URL }}' ? 'https://eventra.fly.dev' : PORTAL_API_URL,
    MANAGEMENT_API_URL: MANAGEMENT_API_URL == '{{ settings.MANAGEMENT_API_URL }}' ? 'https://eventra-manager.fly.dev' : MANAGEMENT_API_URL,
}

if(typeof process.env.REACT_APP_DOCKER_DEV !== 'undefined' && process.env.REACT_APP_DOCKER_DEV == 'true') {
    PORTAL_API_URL = process.env.REACT_APP_PORTAL_API_URL;
    MANAGEMENT_API_URL = process.env.REACT_APP_MANAGEMENT_API_URL;
    
    globalSettings['PORTAL_API_URL'] = PORTAL_API_URL;
    globalSettings['MANAGEMENT_API_URL'] = MANAGEMENT_API_URL;
}

if (document.location.href.indexOf('dashboard.selwyn.digper.tech') > -1) {
    globalSettings['PORTAL_API_URL'] = 'https://api.selwyn.digper.tech';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwyn.digper.tech';
}

if (document.location.href.indexOf('dashboard.selwynevents.com') > -1) {
    globalSettings['PORTAL_API_URL'] = 'https://api.selwynevents.com';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwynevents.com';
}

if (document.location.href.indexOf('localhost') > -1) {
    globalSettings['PORTAL_API_URL'] = 'http://localhost:8000';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwyn.digper.tech';
    globalSettings['PORTAL_API_URL'] = 'https://api.selwyn.digper.tech';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwyn.digper.tech';
}

if (document.location.href.indexOf('instances.selwyn.digper.tech') > -1) {
    globalSettings['PORTAL_API_URL'] = `${document.location.href.split('/')[0]}//${document.location.href.split('/')[2].replace('dashboard.selwyn', 'api.selwyn')}`;
}

const base_url = globalSettings.PORTAL_API_URL;

export const getConfiguration = async () => {
    const cachedConfig = localStorage.getItem('dashboard_configuration_cache');
    const cachedConfigTimestamp = localStorage.getItem('dashboard_configuration_cache_timestamp');
    const oneHour = 60 * 60 * 1000;

    if (cachedConfig && cachedConfigTimestamp && (new Date().getTime() - cachedConfigTimestamp < oneHour)) {
        const configuration_cache = JSON.parse(cachedConfig);
        return configuration_cache;
    }

    const base_url = globalSettings.PORTAL_API_URL;
    const configuration_url = `${base_url}/manager/configuration`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${await getAuthToken()}`
    }

    const config = await fetch(configuration_url, { headers: headers });
    const config_struct = await config.json();

    localStorage.setItem('dashboard_configuration_cache', JSON.stringify(config_struct));
    localStorage.setItem('dashboard_configuration_cache_timestamp', new Date().getTime());
    return config_struct;
}

export const updateConfiguration = async ({
    name,
    logo,
    header_logo,
    description,
    contact_email,
    primary_color,
    secondary_color,
    registration_enabled,
    terms,
    portal_url,
    require_coupon,
    maximum_industries,
    custom_css,
    custom_js,
    colour_off_white_background,
    colour_menu_main,
    colour_menu_dark,
    colour_menu_light,
    colour_heading_success,
    colour_heading_hover,
    colour_cancel,
    colour_cancel_hover,
    colour_grey,
    colour_waiting_header,
}) => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const formdata = new FormData();
    if(!!name) formdata.append("name", name);
    if(!!description) formdata.append("description", description);
    if(!!contact_email) formdata.append("contact_email", contact_email);
    if(!!primary_color) formdata.append("primary_color", primary_color);
    if(!!secondary_color) formdata.append("secondary_color", secondary_color);
    if(typeof registration_enabled != 'undefined') {
        if(registration_enabled == true) {
            formdata.append("registration_enabled", true);
        } else {
            formdata.append("registration_enabled", false);
        }
    }
    if(!!portal_url) formdata.append("portal_url", portal_url);
    if(!!terms) formdata.append("terms", terms);
    if(typeof require_coupon != 'undefined') {
        if(require_coupon == true) {
            formdata.append("require_coupon", true);
        } else {
            formdata.append("require_coupon", false);
        }
    }
    if(!!custom_css) formdata.append("custom_css", custom_css);
    if(!!custom_js) formdata.append("custom_js", custom_js);
    if(!!logo && logo.split(':')[0] == 'data') formdata.append("logo", logo);
    if(!!header_logo && header_logo.split(':')[0] == 'data') formdata.append("header_logo", header_logo);

    if(!!maximum_industries) formdata.append("maximum_industries", maximum_industries);

    if(!!colour_off_white_background) formdata.append("colour_off_white_background", colour_off_white_background);
    if(!!colour_menu_main) formdata.append("colour_menu_main", colour_menu_main);
    if(!!colour_menu_dark) formdata.append("colour_menu_dark", colour_menu_dark);
    if(!!colour_menu_light) formdata.append("colour_menu_light", colour_menu_light);
    if(!!colour_heading_success) formdata.append("colour_heading_success", colour_heading_success);
    if(!!colour_heading_hover) formdata.append("colour_heading_hover", colour_heading_hover);
    if(!!colour_cancel) formdata.append("colour_cancel", colour_cancel);
    if(!!colour_cancel_hover) formdata.append("colour_cancel_hover", colour_cancel_hover);
    if(!!colour_grey) formdata.append("colour_grey", colour_grey);
    if(!!colour_waiting_header) formdata.append("colour_waiting_header", colour_waiting_header);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/manager/configuration`, requestOptions);
        const data = await response.json();

        // delete configuration cache
        localStorage.removeItem('dashboard_configuration_cache');
        localStorage.removeItem('dashboard_configuration_cache_timestamp');

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const updateIntegrationConfiguration = async ({
    stripe_api_key,
    stripe_private_api_key,
    brevo_api_key,
}) => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const formdata = new FormData();
    if(!!stripe_api_key) formdata.append("stripe_api_key", stripe_api_key);
    if(!!stripe_private_api_key) formdata.append("stripe_private_api_key", stripe_private_api_key);
    if(!!brevo_api_key) formdata.append("brevo_api_key", brevo_api_key);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/manager/configuration`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

// bootstrap brevo
export const bootstrapBrevo = async () => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/v1/bootstrap_brevo`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

// bootstrap stripe
export const bootstrapStripe = async () => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/v1/bootstrap_stripe`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

// brevo_ok
export const brevoOk = async () => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/v1/brevo_ok`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

// stripe ok 
export const stripeOk = async () => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/v1/stripe_ok`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}