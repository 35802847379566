import React, { act, useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Select,
  Checkbox,
  Input as TextInput,
  Textarea,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Search, Inject } from '@syncfusion/ej2-react-grids';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';
import { getProfile, toggle2faOnProfile, get2FAQRCode } from './lib/profile';
import { updateEventInstanceCommunication, attendeeAttendEvent, attendeeLeftEvent } from './lib/events';
import { crmContactMessage } from './lib/email';
import { getVenues, getEvents, getEventInstances, finaliseEventInstance, getEventAttendees, getEventWaitingListAttendees, profilesAttendingEvent } from './lib/events';
import { Header, TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { getIndustryDictionary } from './lib/dictionaries';
import { get, set } from 'react-hook-form';

const styles = {
    tabViewComponent: {
        marginTop: '20px'
    }
}

const prototypeActiveEvent = {
    id: 1,
    name: 'Test Event',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    location: 'Test Location',
    description: 'Test Description',
    status: 'active',
    attendees: 100,
    revenue: 1000,
    cost: 500,
    profit: 500,
    marketing_status: 'active'
}

const App = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ loaded, setLoaded ] = useState(false);
    const [ activeEvent, setActiveEvent ] = useState(false);
    const [ activeEventAttendees, setActiveEventAttendees ] = useState([]);
    const [ activeEventWaitingList, setActiveEventWaitingList ] = useState([]);

    const [ events, setEvents ] = useState([]);
    const [ eventsLoaded, setEventsLoaded ] = useState(false);
    const [ eventVenues, setEventVenues ] = useState([]);
    const [ eventIndustries, setEventIndustries ] = useState([]);
    const [ eventVenuesLoaded, setEventVenuesLoaded ] = useState(false);
    const [ eventInstances, setEventInstances ] = useState([]);
    const [ eventInstancesLoaded, setEventInstancesLoaded ] = useState(false);
    const [ listFinalised, setListFinalised ] = useState(false);

    const [ eventCommEmail, setEventCommEmail ] = useState("");

    const toast = useToast();
  
    useEffect(() => {
        isLoggedIn().then((loggedIn) => {
            if(!loggedIn) {
                window.location.href = '/';
            }
        });

        if(!eventVenuesLoaded) {
            getVenues().then(venues => {
                getIndustryDictionary().then(industries => {
                    setEventIndustries(industries);
                    if(!eventsLoaded) {
                        getEvents().then(events => {
                        setEvents(events.map((event) => {
                            return {
                                ...event,
                                id: event.url.split('/')[event.url.split('/').length - 2],
                                venue: venues.find(({url}) => url === event.venue)?.name,
                                start_date: moment(event.start_date).format('YYYY-MM-DD'),
                                end_date: moment(event.end_date).format('YYYY-MM-DD'),
                            }
                        }));
                        setEventsLoaded(true);
                        });
                    };
            
                    if(!eventInstancesLoaded) {
                        getEventInstances().then(eventInstances => {
                            setEventInstances(eventInstances.map((eventInstance) => {
                                return {
                                    ...eventInstance,
                                    id: eventInstance.url.split('/')[eventInstance.url.split('/').length - 2],
                                    start_date: moment(eventInstance.start_date).format('YYYY-MM-DD'),
                                    end_date: moment(eventInstance.end_date).format('YYYY-MM-DD'),
                                }
                            }));
                        });
                        setEventInstancesLoaded(true);
                    }
            
                    setEventVenues(venues);
                    setEventVenuesLoaded(true); 
                    setLoaded(true);
                });
            });
        }
    });

    const toolbarOptions = ['Search'];
    const searchOptions = {
        fields: ['name', 'description', 'start_date', 'end_date', 'venue'],
        ignoreCase: true,
        operator: 'contains'
    };

    const PlanEventComponent = ({eventCommEmailText}) => {
        const [ saving, setSaving ] = useState(false);
        const [ attendee, setAttendee ] = useState({});
        
        const [ isModalOpen, setIsModalOpen ] = useState(false);
        const [ contactTitle, setContactTitle ] = useState("");
        const [ emailMessage, setEmailMessage ] = useState("");

        const [ eventCommEmail, setEventCommEmail ] = useState(eventCommEmailText);

        const toast = useToast();

        const [ eventAttendees, setEventAttendees ] = useState(Array.from({ length: 20 }, (_, i) => ({
            name: `Attendee ${i + 1}`,
            email: `attendee${i + 1}@example.com`,
            phone: `555-000-${String(i + 1).padStart(4, '0')}`
        })));

        const handleSendEmail = () => {
            // Simulate sending email
            setTimeout(() => {
                toast({
                    title: "Email sent.",
                    description: `Your message to ${attendee.name} has been sent.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setIsModalOpen(false);
            }, 1000);
        };

        const updateInstance = async () => {
            const update_event = {
                id: activeEvent.id,
                comm_1week: activeEvent.comm_1week,
                comm_5day: activeEvent.comm_5day,
                comm_2day: activeEvent.comm_2day,
                comm_1day: activeEvent.comm_1day,
                comm_12hour: activeEvent.comm_12hour,
                comm_6hour: activeEvent.comm_6hour,
                comm_1hour: activeEvent.comm_1hour,
                comm_30minutes: activeEvent.comm_30minutes,
                comm_5minutes: activeEvent.comm_5minutes,
                comm_1minute: activeEvent.comm_1minute,
                eventCommEmail: eventCommEmail
            }
    
            const response = await updateEventInstanceCommunication(update_event);
    
            if(!!response) {
                toast({
                    title: "Event updated.",
                    description: `Event ${activeEvent.name} has been updated.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error updating event.",
                    description: `There was an error updating event ${activeEvent.name}.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }

        const promoteAttendee = (row) => async () => {
            const attend = await attendeeAttendEvent(row.id, activeEvent.id);

            if(!!attend) {
                toast({
                    title: "Attendee added.",
                    description: `Attendee ${row.name} has been added to the event.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                getEventAttendees({ event_instance_id: activeEvent.id }).then(attendees => {
                    setActiveEventAttendees(activeEventAttendees.map(attendee => ({
                        ...attendee,
                        industry: eventIndustries.find(({url}) => url === attendee.industry)?.name,
                    })));
                });
            } else {
                toast({
                    title: "Error adding attendee.",
                    description: `There was an error adding attendee ${row.name} to the event.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        return (
            <Box style={styles.tabViewComponent}>
                <Heading as="h5">Event Name: {activeEvent.name}</Heading>

                <Box>
                    <br />
                    <Text><strong>Date: {moment(activeEvent.start_date).format('DD/MM/YYYY')} {activeEvent.start_time}</strong></Text>
                    <Text><strong>Location:</strong> {eventVenues.find(venue => venue.url === activeEvent.venue_id)?.name}</Text>
                    <Text><strong>Description: </strong></Text>
                    <Text>{activeEvent.description}</Text>
                    <br />
                </Box>

                <Box>
                    <Stack align="center" direction="row" spacing={4}>
                        <Text><strong>Reminder Frequency</strong></Text>
                        <br />
                        <Checkbox defaultChecked={activeEvent.comm_1week} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_1week: e.target.checked })}>1 week</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_5day} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_5day: e.target.checked })}>5 day</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_2day} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_2day: e.target.checked })}>2 day</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_1day} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_1day: e.target.checked })}>1 day</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_12hour} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_12hour: e.target.checked })}>12 hour</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_6hour} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_6hour: e.target.checked })}>6 hour</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_1hour} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_1hour: e.target.checked })}>1 hour</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_30minutes} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_30minutes: e.target.checked })}>30 min</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_5minutes} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_5minutes: e.target.checked })}>5 min</Checkbox>
                        <Checkbox defaultChecked={activeEvent.comm_1minute} spacing={1} onChange={(e) => setActiveEvent({ ...activeEvent, comm_1minute: e.target.checked })}>1 min</Checkbox>
                    </Stack>
                    <br />
                    <Text><strong>Messaging</strong></Text>
                    <Textarea 
                        value={eventCommEmail}
                        onChange={(e) => setEventCommEmail(e.target.value)}
                        placeholder="Enter your message"
                        size="sm"
                        style={{ width: '100%', backgroundColor: 'white' }}
                    />

                    <Text><string>Variables Available</string>: {["event_name", "event_date", "event_location", "event_address", "event_postcode", "event_region", "event_format", "event_description"].map(s => `{{${s}}}`).join(', ')}</Text>
                    
                    <Button colorScheme="blue" size="xs" mt={2} onClick={async () => {
                        setSaving(true);
                        await updateInstance();
                        setSaving(false);
                    }}>{ saving ? 'Saving' : 'Save' }</Button>
                </Box>
                <Box>
                    <br />
                    <Text><strong>Event Attendees</strong></Text>
                    <br />

                    <Modal size="xl" isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Contact {attendee.name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <TextInput value={contactTitle} onChange={(e) => setContactTitle(e.target.value) } placeholder="Message Title" />
                                <p>&nbsp;</p>
                                <Textarea
                                    value={emailMessage}
                                    onChange={(e) => setEmailMessage(e.target.value)}
                                    placeholder="Enter your message"
                                    size="sm"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleSendEmail}>
                                    Send
                                </Button>
                                <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    
                    <GridComponent dataSource={activeEventAttendees} allowPaging={true} pageSettings={{ pageSize: 8 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                        <ColumnsDirective>
                            <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                            <ColumnDirective field='email' headerText='Email' width='150' textAlign='Left' />
                            <ColumnDirective field='company_name' headerText='Company' width='100' textAlign='Left' />
                            <ColumnDirective headerText='' width='100' textAlign='Center' template={row => (
                                <Button size="xs" colorScheme='blue' onClick={() => {
                                    setAttendee(row);
                                    setIsModalOpen(true);
                                }}>Contact Attendee</Button>
                            )} />
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar, Search]} />
                    </GridComponent>
                </Box>
                <Box>
                    <Flex justify="space-between" mt={4}>
                        <Box w="33%" p={4} bg="gray.200">
                            <Text><strong>Priority Waiting Lists</strong></Text>
                            <br />
                            <GridComponent dataSource={activeEventWaitingList.filter(({priority}) => priority == 1 )} allowPaging={true} pageSettings={{ pageSize: 8 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective field='name' headerText='Name' width='75' textAlign='left' />
                                    <ColumnDirective field='email' headerText='Email'textAlign='left' />
                                    <ColumnDirective headerText='Actions' width='125' textAlign='left' template={row => (
                                        <HStack spacing={2}>
                                            <Button size="xs" colorScheme='green' onClick={promoteAttendee(row)}>
                                                Give Ticket
                                            </Button>
                                        </HStack>
                                    )} />
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, Search]} />
                            </GridComponent>
                        </Box>
                        <Box w="33%" p={4} bg="gray.300">
                        <Text><strong>Standard Waiting Lists</strong></Text>
                            <br />
                            <GridComponent dataSource={activeEventWaitingList.filter(({priority}) => priority > 1 )} allowPaging={true} pageSettings={{ pageSize: 8 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective field='name' headerText='Name' width='75' textAlign='left' />
                                    <ColumnDirective field='email' headerText='Email'textAlign='left' />
                                    <ColumnDirective headerText='Actions' width='125' textAlign='left' template={row => (
                                        <HStack spacing={2}>
                                            <Button size="xs" colorScheme='green' onClick={promoteAttendee(row)}>
                                                Give Ticket
                                            </Button>
                                        </HStack>
                                    )} />
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, Search]} />
                            </GridComponent>
                        </Box>
                        <Box w="33%" p={4} bg="gray.400">
                        <Text><strong>Attendees with Gifted Tickets</strong></Text>
                            <br />
                            <GridComponent dataSource={activeEventAttendees.filter(({ gifted_by }) => !!gifted_by)} allowPaging={true} pageSettings={{ pageSize: 8 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                                    <ColumnDirective field='email' headerText='Email' width='150' textAlign='Left' />
                                    <ColumnDirective field='phone' headerText='Phone' width='100' textAlign='Left' />
                                </ColumnsDirective>
                                <Inject services={[Page, Toolbar, Search]} />
                            </GridComponent>
                        </Box>
                    </Flex>
                </Box>
                <br /><br /><br />
            </Box>
        )
    }

    const RunEventComponent = () => {
        const [attendees, setAttendees] = useState();

        const toast = useToast();

        const [selectedAttendee, setSelectedAttendee] = useState(null);
        const [isContactModalOpen, setIsContactModalOpen] = useState(false);
        const [contactTitle, setContactTitle] = useState("");
        const [contactMessage, setContactMessage] = useState("");

        useEffect(() => {
            setAttendees(activeEventAttendees);
        }, [activeEventAttendees]);

        const handleMarkAttending = (attendee_row) => {
            // is attendee already attending?
            const attending = activeEventAttendees.find(attendee => attendee.profile_id === attendee_row.profile_id).attending;
            if(attending) {
                attendeeLeftEvent({
                    profile_id: attendee_row.profile_id.split('/')[attendee_row.profile_id.split('/').length - 2],
                    event_instance_id: activeEvent.id
                });

                toast({
                    title: "Attendee left.",
                    description: `${attendee_row.name} has been removed from the attending list.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                attendeeAttendEvent({
                    profile_id: attendee_row.profile_id.split('/')[attendee_row.profile_id.split('/').length - 2],
                    event_instance_id: activeEvent.id
                });

                toast({
                    title: "Attendee arrived.",
                    description: `${attendee_row.name} has been added to the attending list.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }

            setActiveEventAttendees(activeEventAttendees.map(attendee => attendee.profile_id === attendee_row.profile_id ? { ...attendee, attending: !attending } : attendee));
        };

        const handleContactAttendee = (attendee) => {
            setSelectedAttendee(attendee);
            setIsContactModalOpen(true);
        };

        const handleSendContactMessage = async () => {
            await crmContactMessage({
                subject: contactTitle,
                email: selectedAttendee.email,
                message: contactMessage
            });

            setTimeout(() => {
                toast({
                    title: "Message sent.",
                    description: `Your message to ${selectedAttendee.name} has been sent.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setIsContactModalOpen(false);
            }, 1000);

            setContactTitle("");
            setContactMessage("");

            setIsContactModalOpen(false);
        };

        return (
            <Box style={styles.tabViewComponent}>
                <Box>
                    <GridComponent dataSource={attendees} allowPaging={true} pageSettings={{ pageSize: 20 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                        <ColumnsDirective>
                            <ColumnDirective headerText='Actions' width='50' textAlign='Center' template={row => (
                                <HStack spacing={2}>
                                    <Button size="xs" colorScheme={row.attending ? 'green' : 'red'} onClick={() => handleMarkAttending(row)}>
                                        {row.attending ? 'Arrived' : 'Waiting'}
                                    </Button>
                                    <Button size="xs" colorScheme='blue' onClick={() => handleContactAttendee(row)} style={{
                                        display: row.attending ? 'none' : 'inline'
                                    }}>Contact</Button>
                                </HStack>
                            )} />
                            <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                            <ColumnDirective field='email' headerText='Email' width='150' textAlign='Left' />
                            <ColumnDirective field='phone' headerText='Phone' width='100' textAlign='Left' />
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar, Search]} />
                    </GridComponent>

                    <Modal size="xl" isOpen={isContactModalOpen} onClose={() => setIsContactModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Contact {selectedAttendee?.name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <TextInput value={contactTitle} onChange={(e) => setContactTitle(e.target.value) } placeholder="Message Title" />
                                <p>&nbsp;</p>
                                <Textarea
                                    value={contactMessage}
                                    onChange={(e) => setContactMessage(e.target.value)}
                                    placeholder="Enter your message"
                                    size="sm"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleSendContactMessage}>
                                    Send
                                </Button>
                                <Button variant="ghost" onClick={() => setIsContactModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Box>
            </Box>
        )
    }

    const CompleteEventComponent = () => {
        const [delegates, setDelegates] = useState([]);

        useEffect(() => {
            setDelegates(activeEventAttendees.map(attendee => ({
                id: attendee.id,
                name: attendee.name,
                company: attendee.company_name,
                industry: attendee.industry,
                email: attendee.email,
                phone: attendee.phone,
                hidden: !!attendee.marketing_preferences && attendee.marketing_preferences.delegate_list !== false,
                includeEmail: !!attendee.marketing_preferences && attendee.marketing_preferences.delegate_remove_email !== true,
                includePhone: !!attendee.marketing_preferences && attendee.marketing_preferences.delegate_remove_mobile !== true,
            })));
        }, [activeEventAttendees]);

        const toggleHidden = (row) => {
            console.log({row});
            setDelegates(delegates.map(delegate => delegate.email === row.email ? { ...delegate, hidden: !delegate.hidden } : delegate));
        };

        const toggleIncludePhone = (row) => {
            setDelegates(delegates.map(delegate => delegate.email === row.email ? { ...delegate, includePhone: !delegate.includePhone } : delegate));
        }

        const toggleIncludeEmail = (row) => {
            setDelegates(delegates.map(delegate => delegate.email === row.email ? { ...delegate, includeEmail: !delegate.includeEmail } : delegate));
        }

        const downloadDelegateList = () => {
            // create csv
            let csv = 'Name,Company,Industry,Email,Phone\n';
            delegates
            .filter(delegate => !delegate.hidden)
            .map(delegate => ({
                ...delegate,
                email: delegate.includeEmail ? delegate.email : '',
                phone: delegate.includePhone ? delegate.phone : ''
            }))
            .forEach(delegate => {
                csv += `${delegate.name},${delegate.company},${delegate.industry},${delegate.email},${delegate.phone}\n`;
            });

            // download csv
            const element = document.createElement('a');
            const file = new Blob([csv], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = 'delegate_list.csv';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }

        return (
            <Box style={styles.tabViewComponent}>
            { listFinalised && <Box>
                <Text><Button colorScheme="blue" size="xs" onClick={downloadDelegateList}>Download</Button> <strong>Event List Finalised </strong></Text>
                <br />
            </Box> }
            <Box>
                <GridComponent dataSource={delegates} allowPaging={true} pageSettings={{ pageSize: 10 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                <ColumnsDirective>
                    <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                    <ColumnDirective field='company' headerText='Company' width='100' textAlign='Left' />
                    <ColumnDirective field='industry' headerText='Industry' width='100' textAlign='Left' />
                    <ColumnDirective field='email' headerText='Email' width='150' textAlign='Left' />
                    <ColumnDirective field='phone' headerText='Phone' width='100' textAlign='Left' />
                    <ColumnDirective headerText='Rules' width='200' textAlign='Center' template={row => (
                    <>
                        { !listFinalised && <>
                        <Button size="xs" colorScheme="red" onClick={() => toggleHidden(row)}>
                            {row.hidden ? 'Unhide' : 'Hide'}
                        </Button>&nbsp;
                        <Button size="xs" colorScheme="blue" onClick={() => toggleIncludeEmail(row)}>
                            {row.includeEmail ? 'Exclude Email' : 'Include Email'}
                        </Button>&nbsp;
                        <Button size="xs" colorScheme="blue" onClick={() => toggleIncludePhone(row)}>
                            {row.includePhone ? 'Exclude Phone' : 'Include Phone'}
                        </Button>
                        </> }
                        { listFinalised && <>
                        <Text>
                            Hide on delegate list: <strong>{row.hidden ? "Yes" : "No" }</strong>&nbsp;
                            Exclude Email: <strong>{!row.includeEmail ? "Yes" : "No" }</strong>&nbsp;
                            Exclude Phone: <strong>{!row.includePhone ? "Yes" : "No" }</strong>&nbsp;
                        </Text>
                        </> }
                    </>
                    )} />
                </ColumnsDirective>
                <Inject services={[Page]} />
                </GridComponent>
            </Box>

            { !listFinalised && <Box>
                <Button mt={4} colorScheme="blue" size="xs" onClick={async () => {
                    await finaliseEventInstance(activeEvent, delegates);
                    setListFinalised(true);

                    getEventInstances().then(eventInstances => {
                        setEventInstances(eventInstances.map((eventInstance) => {
                            return {
                                ...eventInstance,
                                id: eventInstance.url.split('/')[eventInstance.url.split('/').length - 2],
                                start_date: moment(eventInstance.start_date).format('YYYY-MM-DD'),
                                end_date: moment(eventInstance.end_date).format('YYYY-MM-DD'),
                            }
                        }));
                    });
                    
                    alert('List Finalised');
                }}>Finalise List</Button>
            </Box> }
            </Box>
        )
    }

    return (
        <ChakraProvider theme={theme}>
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full">
            <DrawerContent>
                <SidebarContent onClose={onClose} />
            </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
            { activeEvent && <>
                <Heading as='h3' size='lg' mb={4}>
                    Event Conductor: {activeEvent.name} &nbsp;&nbsp;
                    <Button colorScheme="blue" onClick={() => {
                        setActiveEvent(false);
                        setActiveEventAttendees([]);
                        setActiveEventWaitingList([]);
                        setEventCommEmail("")
                    }} size="xs">Manage Another Event</Button>
                </Heading>
                </> }
            { !activeEvent && <><Heading as='h3' size='lg' mb={4}>Event Conductor</Heading></> }
                <Flex>
                    { !loaded && <Text>...</Text> }
                    
                    { loaded && !activeEvent && <Box>
                        <Text style={{marginBottom: 10}}>Please select an event...</Text>
                        <GridComponent dataSource={eventInstances} allowPaging={true} pageSettings={{ pageSize: 25 }} toolbar={toolbarOptions} searchSettings={searchOptions}>
                            {/* Columns */}
                            <ColumnsDirective>
                                <ColumnDirective headerText='Actions' width={75} template={row => (
                                    <Button size="xs" colorScheme={row.finalised ? 'red' : 'blue'} onClick={async () => {
                                        setActiveEvent(eventInstances.find(({id}) => id === row.id));
                                        const attendees = await getEventAttendees({ event_instance_id: row.id });
                                        setActiveEventAttendees(attendees.map(attendee => ({
                                            ...attendee,
                                            industry: eventIndustries.find(({url}) => url === attendee.industry)?.name,
                                        })));

                                        const profiles = await profilesAttendingEvent({ event_instance_id: row.id });
                                        for (const profile of profiles) {
                                            const attendee = attendees.find(({profile_id}) => profile_id === profile.profile_id);
                                            console.log({ attendee, attendees, profiles });
                                            if(attendee) {
                                                setActiveEventAttendees(attendees.map(attendee => attendee.profile_id === profile.profile_id ? { ...attendee, attending: attendee.attended } : attendee));
                                            }
                                        }
                                        
                                        const waitingList = await getEventWaitingListAttendees({ event_instance_id: row.id });
                                        setActiveEventWaitingList(waitingList);

                                        setEventCommEmail(row.eventCommEmail);

                                        setListFinalised(row.finalised);
                                    }}>{ row.finalised ? "Event Finalised" : "Select Event" }</Button>
                                )} />
                                <ColumnDirective field='name' headerText='Name' width='100' textAlign='Left' />
                                <ColumnDirective field='description' headerText='Description' width='100' textAlign='Left' />
                                <ColumnDirective field='start_date' headerText='Start Date' width='100' textAlign='Left' />
                                <ColumnDirective field='end_date' headerText='End Date' width='100' textAlign='Left' />
                                <ColumnDirective field='venue' headerText='Venue' width='100' textAlign='Left' />
                            </ColumnsDirective>
                            <Inject services={[Page, Toolbar, Search]}/>
                        </GridComponent>
                    </Box>}
                    
                    { loaded && activeEvent && <Box>
                        {/** syncfusion simpletabs component, 3 tabs, plan event, run event, complete event */}

                        <TabComponent>
                            <TabItemsDirective>
                                <TabItemDirective header={{ text: 'Plan Event' }} content={() => <PlanEventComponent eventCommEmailText={eventCommEmail} />} />
                                <TabItemDirective header={{ text: 'Run Event' }} content={RunEventComponent} />
                                <TabItemDirective header={{ text: 'Complete Event' }} content={CompleteEventComponent} />
                            </TabItemsDirective>
                        </TabComponent>
                        
                    </Box>}
                </Flex>
            <Box mt={4}></Box>
            </Box>
        </Box>
        </ChakraProvider>
    )
}

export default App