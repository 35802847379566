import moment from "moment";

import { getAuthToken } from "./browser_state";
import { getProfile, getProfileById } from "./profile";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getVenues = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/venues/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const venues = await response.json();
    return venues.results;
}

export const searchEvents = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/events/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const events = await response.json();
    return events.results;
}

export const searchEventInstances = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instances/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const instances = await response.json();
    return instances.results;
}

export const isAttendingEvent = async ({ event_id: event }) => {
    const profile = await getProfile();
    const profile_id = profile.id;

    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const attending = await response.json();
    
    return attending.filter(({ event_instance_id }) => event_instance_id == `${base_url}/api/event_instances/${event}/`).length > 0;
};

export const profilesAttendingEvent = async ({ event_instance_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/?event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    const attending = await response.json();
    return attending;
}

export const attendEvent = async ({ event_id }) => {
    const profile = await getProfile();
    const profile_id = profile.url;

    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("profile_id", profile_id);
    body.append("event_instance_id", `${base_url}/api/event_instances/${event_id}/`);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const attending = await response.json();
    return attending;
}

export const attendeeAttendEvent = async ({ profile_id, event_instance_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/?profile_id=${profile_id}&event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    const attending = await response.json();
    const event_instance_profile_url = attending.find(({ profile_id: profile_id_url }) => profile_id_url.split('/')[profile_id_url.split('/').length - 2] === profile_id).url;
    const event_instance_profile_id = event_instance_profile_url.split('/')[event_instance_profile_url.split('/').length - 2];

    // update the event instance profile to attended
    headers.append('Content-Type', 'application/json');
    const update_response = await fetch(`${base_url}/api/event_instance_profiles/${event_instance_profile_id}/`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ attended: true }),
        redirect: 'follow'
    })

    return await profilesAttendingEvent({ event_instance_id });
}

export const attendeeLeftEvent = async ({ profile_id, event_instance_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/?profile_id=${profile_id}&event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    const attending = await response.json();
    const event_instance_profile_url = attending.find(({ profile_id: profile_id_url }) => profile_id_url.split('/')[profile_id_url.split('/').length - 2] === profile_id).url;
    const event_instance_profile_id = event_instance_profile_url.split('/')[event_instance_profile_url.split('/').length - 2];

    // update the event instance profile to attended
    headers.append('Content-Type', 'application/json');
    const update_response = await fetch(`${base_url}/api/event_instance_profiles/${event_instance_profile_id}/`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ attended: false }),
        redirect: 'follow'
    })

    return await profilesAttendingEvent({ event_instance_id });
}


export const isAttendingEventWaitingList = async ({ event_id: event }) => {
    const profile = await getProfile();
    const profile_id = profile.id;

    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profile_waiting_lists/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const attending = await response.json();
    
    return attending.filter(({ event_instance_id }) => event_instance_id == `${base_url}/api/event_instances/${event}/`).length > 0;
};

export const attendEventWaitingList = async ({ event_id }) => {
    const profile = await getProfile();
    const profile_id = profile.url;

    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("profile_id", profile_id);
    body.append("event_instance_id", `${base_url}/api/event_instances/${event_id}/`);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profile_waiting_lists/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const attending = await response.json();
    return attending;
}

export const getEventFormats = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_formats/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const formats = await response.json();
    return formats.results;
}

export const createEventFormat = async ({ name, description }) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("name", name);
    body.append("description", description);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_formats/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const format = await response.json();
    return format;
}

export const deleteEventFormat = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_formats/${id}/`, {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    })
    
    return await getEventFormats()
}

export const getEventLocations = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_locations/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const locations = await response.json();
    return locations.results;
}

export const createEventLocation = async ({ name, description }) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("name", name);
    body.append("description", description);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_locations/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const location = await response.json();
    return location;
}

export const deleteEventLocation = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_locations/${id}/`, {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    })
    
    return await getEventLocations()
}

export const getEventRegions = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_regions/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const regions = await response.json();
    return regions.results;
}

export const createEventRegion = async ({ name, description }) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("name", name);
    body.append("description", description);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_regions/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const region = await response.json();
    return region;
}

export const deleteEventRegion = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_regions/${id}/`, {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    })
    
    return await getEventRegions()
}

export const createVenue = async ({ name, address_line_1, address_line_2, city, country, county, postcode }) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("name", name);
    body.append("address_line_1", address_line_1);
    body.append("address_line_2", address_line_2);
    body.append("city", city);
    body.append("country", country);
    body.append("county", county);
    body.append("postcode", postcode);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/venues/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const venue = await response.json();
    return venue;
}

export const deleteVenue = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/venues/${id}/`, {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    })
    
    return await getVenues()
}

export const getEventInstances = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instances/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const instances = await response.json();
    return instances.results;
}

export const updateEventInstanceCommunication = async ({ id, comm_1week, comm_5day, comm_2day, comm_1day, comm_12hour, comm_6hour, comm_1hour, comm_30minutes, comm_5minutes, comm_1minute, eventCommEmail }) => {
    const authToken = await getAuthToken();

    const body = new FormData();

    body.append("comm_1week", comm_1week);
    body.append("comm_5day", comm_5day);
    body.append("comm_2day", comm_2day);
    body.append("comm_1day", comm_1day);
    body.append("comm_12hour", comm_12hour);
    body.append("comm_6hour", comm_6hour);
    body.append("comm_1hour", comm_1hour);
    body.append("comm_30minutes", comm_30minutes);
    body.append("comm_5minutes", comm_5minutes);
    body.append("comm_1minute", comm_1minute);
    body.append("eventCommEmail", eventCommEmail);

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instances/${id}/`, {
        method: 'PATCH',
        headers: headers,
        body: body,
        redirect: 'follow'
    })

    const instance = await response.json();
    return instance;
}   

export const updateEventInstance = async ({ id, name, format, region, location, venue, start_date, start_time, end_date, ticket_price, ticket_price_non_member, ticket_credits, ticket_credits_non_member, ticket_quantity, description, maximum_industry_conflicts, code_of_conduct, duration }) => {
    const authToken = await getAuthToken();

    // get venues and convert venue to url
    const venues = await getVenues();
    const venueObj = venues.find(({ name: venueName }) => venueName === venue);
    venue = venueObj.url;

    // get formats and convert format to url
    const formats = await getEventFormats();
    const formatObj = formats.find(({ name: formatName }) => formatName === format);
    format = formatObj.url;

    // get regions and convert region to url
    const regions = await getEventRegions();
    const regionObj = regions.find(({ name: regionName }) => regionName === region);
    region = regionObj.url;

    // get locations and convert location to url
    const locations = await getEventLocations();
    const locationObj = locations.find(({ name: locationName }) => locationName === location);
    location = locationObj.url;

    const body = new FormData();
    body.append("name", name);
    body.append("format", format);
    body.append("region", region);
    body.append("location", location);
    body.append("venue", venue);
    body.append("start_date", moment(start_date).format("YYYY-MM-DD") + 'T00:00');
    // body.append("start_time", moment(start_time).format("HH:mm")); // TODO: fix this
    body.append("start_time", start_time);
    body.append("duration", duration);
    body.append("end_date", moment(end_date).format("YYYY-MM-DD") + 'T00:00');
    body.append("ticket_price", ticket_price);
    body.append("ticket_price_non_member", ticket_price_non_member);
    body.append("ticket_credits", ticket_credits);
    body.append("ticket_credits_non_member", ticket_credits_non_member);
    body.append("ticket_quantity", ticket_quantity);
    body.append("description", description);
    body.append("maximum_industry_conflicts", maximum_industry_conflicts);
    body.append("code_of_conduct", code_of_conduct);

    body.append("override_information", true);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instances/${id}/`, {
        method: 'PUT',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const instance = await response.json();
    return instance;
}

export const getEvents = async () => {
    const authToken = await getAuthToken();
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/events/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const events = await response.json();
    return events.results;
}

export const createEvent = async ({
    name,
    format,
    region,
    location,
    venue,
    start_date,
    start_time,
    end_date,
    recurringRule,
    ticket_price,
    ticket_price_non_member,
    ticket_credits,
    ticket_credits_non_member,
    ticket_quantity,
    description,
    image1,
    image2,
    image3,
    image4,
    image5,
    comm_1week,
    comm_5day,
    comm_2day,
    comm_1day,
    comm_12hour,
    comm_6hour,
    comm_1hour,
    comm_30minutes,
    comm_5minutes,
    comm_1minute,
    eventCommEmail,
    maximum_industry_conflicts,
    code_of_conduct
}) => {
    const authToken = await getAuthToken();

    // get venues and convert venue to url
    const venues = await getVenues();
    const venueObj = venues.find(({ name: venueName }) => venueName === venue);
    venue = venueObj.url;

    // get formats and convert format to url
    const formats = await getEventFormats();
    console.log(formats, format);
    const formatObj = formats.find(({ name: formatName }) => formatName === format);
    format = formatObj.url;

    // get regions and convert region to url
    const regions = await getEventRegions();
    const regionObj = regions.find(({ name: regionName }) => regionName === region);
    region = regionObj.url;

    // get locations and convert location to url
    const locations = await getEventLocations();
    const locationObj = locations.find(({ name: locationName }) => locationName === location);
    location = locationObj.url;

    const body = new FormData();
    body.append("name", name);
    body.append("format", format);
    body.append("region", region);
    body.append("location", location);
    body.append("venue", venue);
    body.append("start_date", moment(start_date).format("YYYY-MM-DD") + 'T00:00');
    body.append("start_time", moment(start_time).format("HH:mm"));
    body.append("end_date", moment(end_date).format("YYYY-MM-DD") + 'T00:00'); 
    body.append("recurringRule", recurringRule);

    body.append("ticket_price", ticket_price);
    body.append("ticket_price_non_member", ticket_price_non_member);
    body.append("ticket_credits", ticket_credits);
    body.append("ticket_credits_non_member", ticket_credits_non_member);
    body.append("ticket_quantity", ticket_quantity);

    body.append("description", description);

    body.append("comm_1week", comm_1week);
    body.append("comm_5day", comm_5day);
    body.append("comm_2day", comm_2day);
    body.append("comm_1day", comm_1day);
    body.append("comm_12hour", comm_12hour);
    body.append("comm_6hour", comm_6hour);
    body.append("comm_1hour", comm_1hour);
    body.append("comm_30minutes", comm_30minutes);
    body.append("comm_5minutes", comm_5minutes);
    body.append("comm_1minute", comm_1minute);
    body.append("eventCommEmail", eventCommEmail);

    body.append("maximum_industry_conflicts", maximum_industry_conflicts);
    body.append("code_of_conduct", code_of_conduct);

    if (!!image1 && image1.split(':')[0] === 'data') body.append("image1", image1);
    if (!!image2 && image2.split(':')[0] === 'data') body.append("image2", image2);
    if (!!image3 && image3.split(':')[0] === 'data') body.append("image3", image3);
    if (!!image4 && image4.split(':')[0] === 'data') body.append("image4", image4);
    if (!!image5 && image5.split(':')[0] === 'data') body.append("image5", image5);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/events/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const event = await response.json();
    return event;
}

export const updateEvent = async ({
    id,
    name,
    format,
    region,
    location,
    venue,
    start_date,
    start_time,
    duration,
    end_date,
    recurringRule,
    ticket_price,
    ticket_price_non_member,
    ticket_credits,
    ticket_credits_non_member,
    ticket_quantity,
    description,
    image1,
    image2,
    image3,
    image4,
    image5,
    comm_1week,
    comm_5day,
    comm_2day,
    comm_1day,
    comm_12hour,
    comm_6hour,
    comm_1hour,
    comm_30minutes,
    comm_5minutes,
    comm_1minute,
    eventCommEmail,
}) => {
    const authToken = await getAuthToken();

    // get venues and convert venue to url
    const venues = await getVenues();
    const venueObj = venues.find(({ name: venueName }) => venueName === venue);
    venue = venueObj.url;

    // get formats and convert format to url
    const formats = await getEventFormats();
    const formatObj = formats.find(({ name: formatName }) => formatName === format);
    format = formatObj.url;

    // get regions and convert region to url
    const regions = await getEventRegions();
    const regionObj = regions.find(({ name: regionName }) => regionName === region);
    region = regionObj.url;

    // get locations and convert location to url
    const locations = await getEventLocations();
    const locationObj = locations.find(({ name: locationName }) => locationName === location);
    location = locationObj.url;

    const body = new FormData();
    body.append("name", name);
    body.append("format", format);
    body.append("region", region);
    body.append("location", location);
    body.append("venue", venue);
    body.append("start_date", moment(start_date).format("YYYY-MM-DD") + 'T00:00');
    body.append("start_time", start_time);
    body.append("end_date", moment(end_date).format("YYYY-MM-DD") + 'T00:00'); 
    body.append("duration", duration);
    body.append("recurringRule", recurringRule);
    
    body.append("ticket_price", ticket_price);
    body.append("ticket_price_non_member", ticket_price_non_member);
    body.append("ticket_credits", ticket_credits);
    body.append("ticket_credits_non_member", ticket_credits_non_member);
    body.append("ticket_quantity", ticket_quantity);
    
    body.append("description", description);

    body.append("comm_1week", comm_1week);
    body.append("comm_5day", comm_5day);
    body.append("comm_2day", comm_2day);
    body.append("comm_1day", comm_1day);
    body.append("comm_12hour", comm_12hour);
    body.append("comm_6hour", comm_6hour);
    body.append("comm_1hour", comm_1hour);
    body.append("comm_30minutes", comm_30minutes);
    body.append("comm_5minutes", comm_5minutes);
    body.append("comm_1minute", comm_1minute);
    body.append("eventCommEmail", eventCommEmail);

    if (!!image1 && image1.split(':')[0] === 'data') body.append("image1", image1);
    if (!!image2 && image2.split(':')[0] === 'data') body.append("image2", image2);
    if (!!image3 && image3.split(':')[0] === 'data') body.append("image3", image3);
    if (!!image4 && image4.split(':')[0] === 'data') body.append("image4", image4);
    if (!!image5 && image5.split(':')[0] === 'data') body.append("image5", image5);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/events/${id}/`, {
        method: 'PUT',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const event = await response.json();
    return event;
}

export const getEventInstanceJoiningInstructions = async ({ event_instance_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_joining_instructions/?event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const instructions = await response.json();
    return instructions.results;
}

export const createEventInstanceJoiningInstructions = async ({ event_instance_id, type, hyperlink, instructions }) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("event_instance_id", event_instance_id);
    body.append("type", type);
    body.append("hyperlink", hyperlink);
    body.append("instructions", instructions);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_joining_instructions/`, {
        method: 'POST',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const instruction = await response.json();
    return instruction;
}

export const updateEventInstanceJoiningInstructions = async ({ id, type, hyperlink, instructions }) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("type", type);
    body.append("hyperlink", hyperlink);
    body.append("instructions", instructions);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    id = id.split('/')[id.split('/').length - 2];
    const response = await fetch(`${base_url}/api/event_instance_joining_instructions/${id}/`, {
        method: 'PATCH',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const instruction = await response.json();
    return instruction;
}

export const getEventInstanceProfiles = async ({ profile_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/?profile_id=${profile_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const profiles = await response.json();
    return profiles;
}

export const setAttendedEventInstanceProfile = async (id, attended) => {
    const authToken = await getAuthToken();

    const body = new FormData();
    body.append("attended", attended);
    
    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/${id}/`, {
        method: 'PATCH',
        headers: headers,
        body: body,
        redirect: 'follow'
    })
    
    const profile = await response.json();
    return profile;
}

export const getEventAttendees = async ({ event_instance_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profiles/?event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const attendees = await response.json();

    return await Promise.all(attendees.map(async (attendee) => {
        const profile = await getProfileById(attendee.profile_id.split('/')[attendee.profile_id.split('/').length - 2]);
        
        return {
            ...attendee,
            ...profile
        };
    }));
}

export const getEventWaitingListAttendees = async ({ event_instance_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/event_instance_profile_waiting_lists/?event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const attendees = await response.json();

    return await Promise.all(attendees.map(async (attendee) => {
        const profile = await getProfileById(attendee.profile_id.split('/')[attendee.profile_id.split('/').length - 2]);
        
        return {
            ...attendee,
            ...profile
        };
    }));
}

export const finaliseEventInstance = async ({ id }, finalised_list) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${base_url}/api/v1/finalise_event/${id}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(finalised_list),
        redirect: 'follow'
    });
    
    const instance = await response.json();
    return instance;
}