import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  Input,
  Heading,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { useToast } from '@chakra-ui/react';

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, TrendlineDirective, TrendlinesDirective, Inject, Tooltip, LineSeries, ScatterSeries, SplineSeries, Trendlines, Category, DateTime } from '@syncfusion/ej2-react-charts';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';
import { HtmlEditor, Inject as InjectEditor, RichTextEditorComponent, Toolbar, Image, Link, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { TabComponent, TabItemsDirective, TabItemDirective } from '@syncfusion/ej2-react-navigations';
import { getEmailTemplates, saveEmailTemplate } from './lib/email_templates';

const Automation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ emailTemplates, setEmailTemplates ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);

  const toast = useToast()
  
  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!loaded) {
      getEmailTemplates().then(templates => {
        setEmailTemplates(templates);
        setLoaded(true);
      });
    }
  })

  const saveContent = (section, subject, content, emailTemplates) => {
    if (!subject) {
      subject = emailTemplates.find(template => template.name === section)?.subject;
    }

    if (!content) {
      content = emailTemplates.find(template => template.name === section)?.body;
    }

    saveEmailTemplate(section, subject, content);

    toast({
      title: "Template Saved",
      description: "Your template has been saved.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const NewlyRegisteredWelcomeEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input
        value={subject || emailTemplates.find(template => template.name === 'Newly Registered, Welcome')?.subject || ''} 
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Newly Registered, Welcome')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Newly Registered, Welcome', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  };

  const ForgotPasswordEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input
        value={subject || emailTemplates.find(template => template.name === 'Forgot Password')?.subject || ''} 
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Forgot Password')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Forgot Password', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  };

  const EventBookedInEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input 
        value={subject || emailTemplates.find(template => template.name === 'Event Booked In')?.subject || ''} 
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Event Booked In')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Event Booked In', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  }

  const ReferralSent = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input 
        value={subject || emailTemplates.find(template => template.name === 'Referral Sent')?.subject || ''}
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Referral Sent')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Referral Sent', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  }

  const ReferralClaimedEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input 
        value={subject || emailTemplates.find(template => template.name === 'Referral Claimed')?.subject || ''}
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Referral Claimed')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Referral Claimed', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  }

  const EventRegistredEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input 
        value={subject || emailTemplates.find(template => template.name === 'Event Registered')?.subject || ''}
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Event Registered')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Event Registered', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  }
    
  const EventCancelledEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input 
        value={subject || emailTemplates.find(template => template.name === 'Event Cancelled')?.subject || ''}
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Event Cancelled')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Event Cancelled', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  }

  const EventDelegateListAvailableEmail = () => {
    const [subject, setSubject] = useState(false);
    const [body, setBody] = useState(false);

    return (
      <div>
      <br />
      <Input 
        value={subject || emailTemplates.find(template => template.name === 'Delegate List Available')?.subject || ''}
        onChange={(e) => setSubject(e.target.value)} 
        style={{ backgroundColor: 'white' }}
        mb={4}
      />
      <textarea
        style={{ width: '100%', height: '400px', backgroundColor: 'white', padding: '10px' }}
        value={body || emailTemplates.find(template => template.name === 'Delegate List Available')?.body || ''}
        onChange={(e) => setBody(e.target.value)}
      />
      <br />
      <Button colorScheme="teal" variant="solid" onClick={() => saveContent('Delegate List Available', subject, body, emailTemplates)}>
        Save
      </Button>
      </div>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>Automation</Heading>
          <br />
          <div>
            <TabComponent>
              <TabItemsDirective>
                <TabItemDirective header={{ text: 'Newly Registered, Welcome' }} content={() => <NewlyRegisteredWelcomeEmail />} />
                <TabItemDirective header={{ text: 'Forgot Password' }} content={ForgotPasswordEmail} />
                <TabItemDirective header={{ text: 'Event Booked In' }} content={EventBookedInEmail} />
                <TabItemDirective header={{ text: 'Referral Sent' }} content={ReferralSent} />
                <TabItemDirective header={{ text: 'Referral Claimed' }} content={ReferralClaimedEmail} />
                <TabItemDirective header={{ text: 'Event Cancelled' }} content={EventCancelledEmail} />
                <TabItemDirective header={{ text: 'Delegate List Available' }} content={EventDelegateListAvailableEmail} />
              </TabItemsDirective>
            </TabComponent>
          </div>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default Automation